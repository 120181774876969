@use '../../functions/rem' as *;

$health-sprite-width:  336px; // The width of the 1x sprite image
$health-sprite-height: 252px; // The height of the 1x sprite image
$health-sprite-positions: (
  'blood':              0px    0px,
  'diabetes':         -84px    0px,
  'diet':            -168px    0px,
  'digestive':       -252px    0px,
  'endocrine':          0px  -84px,
  'kidney':           -84px  -84px,
  'digestive-liver': -168px  -84px,
  'liver':           -252px  -84px,
  'urologic':           0px -168px,
  'weight':           -84px -168px
);

%health-info-icon-sprite-background {
  background-size: rem($health-sprite-width) rem($health-sprite-height); // Needed to ensure the image scales at tablet size
  background-repeat: no-repeat;
  background-image: url(../images/hi-icon-sprite.png);
  background-image: -webkit-image-set(
    url(../images/hi-icon-sprite.png) 1x,
    url(../images/hi-icon-sprite-2x.png) 2x
  );
  background-image: image-set(
    url(../images/hi-icon-sprite.png) 1x,
    url(../images/hi-icon-sprite-2x.png) 2x
  );
}

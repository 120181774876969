@use 'sass:color';
@use 'sass:list';
@use '../../variables' as *;
@use '../../functions' as *;
@use '../accent-colors' as ac;

$_scopeless-enabled: false;

// Themable pages
%themable-root {
  $default-bg-hover-color: #{color.adjust($black, $lightness: 20%)};
  $fallback-grad: linear-gradient(to bottom, #{rgba($black, 0.5)}, #{rgba($black, 0.8)});
  $var-grad: linear-gradient(to bottom, var(--theme-grad-start-color, #{rgba($black, 0.5)}), var(--theme-grad-end-color, #{rgba($black, 0.8)}));

  %themable-border, .theme-border.theme-border {
    border-color: $black;
    border-color: var(--theme-border-color, #{$black});
  }

  %themable-bg-gradient, .theme-bg-gradient {
    background-image: $fallback-grad;
    background-image: $var-grad;
  }

  %themable-bg, %themable-bg-link, .theme-bg {
    background-color: $black;
    background-color: var(--theme-color, #{$black});
  }

  %themable-bg-link:hover, %themable-bg-link-hover:hover,
  a.theme-bg:hover, a.theme-bg-hover:hover {
    background-color: $default-bg-hover-color;
    background-color: var(--theme-bg-hover-color, #{$default-bg-hover-color});
  }

  %themable-forecolor, .theme-forecolor {
    color: $black;
    color: var(--theme-color, #{$black});
  }

  %themable-vidcorner, .theme-vidcorner {
    .corner .crn-bg {
      fill: $black;
      fill: var(--theme-color, #{$black});
    }
  }

  .lab-news-image:empty {
    background-image: #{$fallback-grad}, url(../images/lab-news.jpg);
    background-image: #{$var-grad}, url(../images/lab-news.jpg);
    background-size: cover;
  }

  // No color (black)
  @include ac.without-accent-class {
    --theme-color: #{$black};
    --theme-bg-hover-color: #{color.adjust($black, $lightness: 20%)};
    --theme-border-color: #{$black};
    --theme-grad-start-color: #{rgba($black, 0.5)};
    --theme-grad-end-color: #{rgba($black, 0.8)};
  }

  // Accent colors
  @each $cls, $colors in $color-themes {
    &.#{$accent-color-prefix}#{""+$cls} {
      $c-flat: list.nth($colors, 1);
      $c-start: rgba(list.nth($colors, 2), list.nth($colors, 4));
      $c-end: rgba(list.nth($colors, 3), list.nth($colors, 4));
      $adjust-colors: (ac.get-accent-color('lavender'), ac.get-accent-color('teal'), ac.get-accent-color('mauve'));
      $c-flat-adj: if(list.index($adjust-colors, $c-flat) == null, $c-flat, color.adjust($c-flat, $lightness: -15%));

      --theme-color: #{$c-flat-adj};
      --theme-bg-hover-color: #{color.adjust($c-flat-adj, $lightness: -10%)};
      --theme-border-color: #{$c-flat};
      --theme-grad-start-color: #{$c-start};
      --theme-grad-end-color: #{$c-end};
    }
  }
}

@mixin enable-themable-pages($scoped: false, $selector: '.themable') {
  @if ($scoped) {
    @if ($selector and $selector != '') {
      #{$selector} {
        @extend %themable-root;
      }
    } @else {
      @extend %themable-root;
    }
  } @else if (not $_scopeless-enabled and $selector and $selector != '') {
    $_scopeless-enabled: true !global;
    @at-root #{$selector} {
      @extend %themable-root;
    }
  }
}

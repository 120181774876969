@use 'sass:color';
@use 'sass:list';
@use 'sass:map';
@use 'sass:meta';
@use '../lib/bourbon';
@use '../variables' as var;

$_accent-color-map: if(var.$accent-color-map, var.$accent-color-map, (
  'green': #5f8038,
)) !default;
$_default-accent-color-name: if(var.$default-accent-color-name, var.$default-accent-color-name,
  if(list.length($_accent-color-map) > 0, list.nth(map.keys($_accent-color-map), 1), 'green')
) !default;
$_default-accent-color: if(var.$default-accent-color, var.$default-accent-color,
  if(map.has-key($_accent-color-map, $_default-accent-color-name), list.nth(map.get($_accent-color-map, $_default-accent-color-name), 1), #5f8038)
) !default;
$_shade-percent: 15%;
$_default-shade-accent-color: color.mix(#000, $_default-accent-color, $_shade-percent);
$_accent-color-prefix: if(var.$accent-color-prefix, var.$accent-color-prefix, 'accent-') !default;

// Gets an accent color hex value by name
@function get-accent-color($name, $prop: 'border-color') {
  $list: map.get($_accent-color-map, $name);
  $len: list.length($list);
  @if $prop == 'background-color' or $prop == 'bg' or $prop == 'background' {
    @return list.nth($list, if($len > 1, 2, 1));
  }
  @if $prop == 'color' or $prop == 'fg' {
    @return list.nth($list, if($len > 2, 3, if($len > 1, 2, 1)));
  }
  @return list.nth($list, 1);
}

// Gets all accent color names
@function get-accent-names() {
  @return map.keys($_accent-color-map);
}

// Placeholders for applying accent colors to elements
%accent-border {
  border-color: $_default-accent-color;
  border-color: var(--accent-border-color, #{$_default-accent-color});
}

%accent-background {
  background-color: $_default-accent-color;
  background-color: var(--accent-bg-color, #{$_default-accent-color});
}

%accent-foreground {
  color: $_default-accent-color;
  color: var(--accent-fg-color, #{$_default-accent-color});
}

%accent-border-darken {
  border-color: $_default-shade-accent-color;
  border-color: var(--accent-border-shade-color, #{$_default-shade-accent-color});
}

%accent-background-darken {
  background-color: $_default-shade-accent-color;
  background-color: var(--accent-bg-shade-color, #{$_default-shade-accent-color});
}

// Define the accent color custom properties based on class (or placeholder)
@each $name in get-accent-names() {
  $fg: get-accent-color($name, 'color');
  $bg: get-accent-color($name, 'background-color');
  $border: get-accent-color($name, 'border-color');
  $sel: "%accent-color-vars-#{$name}";

  %accent-color-#{$name} {
    --accent-fg-color: #{$fg};
    --accent-bg-color: #{$bg};
    --accent-border-color: #{$border};
    --accent-bg-shade-color: #{color.mix(#000, $bg, $_shade-percent)};
    --accent-border-shade-color: #{color.mix(#000, $border, $_shade-percent)};
    --accent-alpha30-color: #{rgba($bg, 0.3)};
  }
}

@mixin enable-accent-color-classes {
  @each $name in get-accent-names() {
    .#{$_accent-color-prefix}#{$name} {
      @extend %accent-color-#{$name};
    }
  }
}

// Targets elements that have an accent color class
@mixin with-accent-class() {
  &[class*="#{$_accent-color-prefix}"] {
    @content;
  }
}

// Targets elements that do not have an accent color class
@mixin without-accent-class() {
  &:not([class*="#{$_accent-color-prefix}"]) {
    @content;
  }
}

@use 'sass:list';
@use '../lib/neat';
@use '../variables' as *;
@use '../functions' as *;
@use '../mixins' as *;
@use 'placeholders';

// Set default box sizing
@include neat.set-border-box-sizing;

// Base font size (1rem)
html, body {
  margin: 0;
  font-size: $em-base;

  @if $tablet-scale and $tablet-scale != 1 {
    @include tablet {
      font-size: $em-base * $tablet-scale;
      -webkit-text-size-adjust: 100%;
    }
  }
}

// Body color (page background)
body {
  color: $text-dark;
  background-color: $bg-outer-body; // #eeeded
  font-family: $font-sans;
}

@mixin dk-grid($withMobile: 1) {
  @include neat.outer-container(rem($container-width));
  padding: 0 rem(list.nth($container-pad, 1));

  @if $withMobile == 1 {
    @include mobile {
      padding: 0 rem(list.nth($container-pad, 2));
    }
  }
}

%dk-grid, .grid {
  @include dk-grid;
}

%dk-stripe, .dk-stripe {
  display: block;

  &:not(.overflow) {
    @extend %clearfix;
  }
}

.float-left {
  display: block;
  float: left;
}

.float-right {
  display: block;
  float: right;
}

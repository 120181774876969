@use '../../functions' as *;

// Anchor adjustment for sticky header
$_anchor-margin: 5.2rem; // 4.2rem header + 1rem typical margin

@mixin anchor-margin($width: $_anchor-margin) {
  scroll-margin-top: $width;

  @supports (scroll-snap-margin-top: #{$width}) {
    scroll-snap-margin-top: #{$width};
  }
}

%anchor-margin {
  @include anchor-margin;
}

@media print {
  %-display-none {
    display: none;
  }

  %-display-none-important {
    display: none !important;
  }

  .usa-banner {
    @extend %-display-none;
  }

  .dk-main-container {
    margin-top: 0 !important;
    padding: 0 !important;
    box-shadow: none !important;

    .dk-leftnav {
      @extend %-display-none-important;
    }
  }

  #main-footer {
    .live-chat {
      @extend %-display-none;
    }
  }

  // Live chat badge
  .livechat-floating {
    &, .livechat-badge {
      @extend %-display-none-important;
    }
  }
}

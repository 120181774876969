// Provides an easy way to prevent margin collapsing.
//
// @example scss - Usage
//   .element {
//     @include no-margin-collapse;
//   }
//
// @example css - CSS Output
//   .element::before, .element::after {
//     content: "";
//     display: table;
//   }

@mixin no-margin-collapse {
  &::before, &::after {
    content: "";
    display: table;
  }
}

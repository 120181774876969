@use 'sass:color';
@use 'sass:list';
@use 'sass:map';
@use 'sass:math';
@use '../lib/bourbon';
@use '../lib/neat';
@use '../common' as *;
@use 'main';
@use 'components/health-info-icon-sprite' as sprite;

// "The NIDDK Director" section on the home and about landing pages
.director-landing.director-landing.director-landing {
  $imgsize: rem(100);

  display: grid;
  grid-template: "photo header" auto "photo links" auto / $imgsize 1fr;
  gap: 1rem;

  @include mobile {
    grid-template: "header photo" auto "links links" auto / 1fr 5rem;
    gap: 1rem 4px;
  }

  .header {
    grid-area: header;
  }

  .photo {
    grid-area: photo;
    align-self: center;
  }

  .links {
    grid-area: links;
  }

  h2 {
    @extend %h2-standard;
    font-weight: normal;
    text-align: left;
    margin: 0;
  }

  img {
    display: block;
    border-radius: 50%;
  }

  .name {
    margin: 0;
    font: normal bold 1rem $open-sans;
    color: $text-dark;
    text-align: left;
  }

  ul {
    display: block;
    margin: 0;
    border-top: 1px solid $divider-stroke;
    padding: 0;
    list-style-type: none;
    font: 1rem $open-sans;

    @include neat.media(min-width 800px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-auto-flow: column;
      gap: 0 rem(10);
      border-top: 0;

      li:nth-child(2n+1) {
        border-width: 1px 0;
      }
    }

    li {
      border: 0 solid $divider-stroke;
      border-width: 0 0 1px;
      margin: 0;
      padding: 0 0.5rem;

      a {
        display: block;
        position: relative;
        padding: 0.6rem rem(8) 0.6rem 0;
        font-family: $open-sans;

        &:hover, &:active {
          &::after {
            @extend %icon-hover-fix;
          }
        }

        &::after {
          @extend %angle-right-after;
          position: absolute;
          right: 0;
          top: calc(50% - #{rem(7)});
          color: $orange;
        }
      }
    }
  }
}

.pt-top-landing {
  // Temporarily overlaps the pt-health-info class; change once designs for R&F are complete
  .hero-unit {
    padding: 2rem 0 3rem;
    background-color: #6b7785;
    background-image: url(../images/hi-bg-hero.jpg);
    background-position: center;
    color: $white;
    font-family: $open-sans;
    text-shadow: rem(1) rem(1) $dark-gray;

    .grid > div {
      display: table-cell;
      vertical-align: middle;
      float: none;

      @include mobile {
        display: block;
        width: 100%;
        text-align: center;
      }
    }

    .thirds {
      text-align: center;
    }

    h1 {
      font: {
        family: $open-sans;
        weight: $semibold;
        size: rem(36);
      }

      display: inline-block;
      padding-bottom: 1.2rem;
      margin: 0 rem(10);
      color: $white;
    }

    p {
      font: {
        family: $merriweather;
        size: rem(18);
      }
      line-height: 1.7;
      margin: rem(10);
      color: $white;
    }

    .button-ghost {
      padding: 1rem 3rem;
    }

    @include mobile {
      .dk-one-third h2 {
        display: none;
      }
    }
  }

  .topics-row {
    .dk-box {
      h2 {
        display: flex;
        min-height: rem(84);
        align-items: center;
        vertical-align: middle;
        font-size: rem(20);

        &:hover {
          text-decoration: none;
          @extend %accent-background-darken;
        }
      }

      & > a:first-of-type {
        color: $white;
        text-decoration: underline;
      }

      ul {
        margin: 0 0 rem(5);

        li {
          padding-bottom: rem(10);

          &:last-of-type {
            padding-bottom: 0;
          }
        }
      }
    }

    @supports (background-blend-mode: multiply) {
      &.topics-bg-polygons .dk-box h2 {
        background-image: url(../images/topics-bg-polygons.png);
        background-blend-mode: multiply;
      }
    }

    @supports (background-blend-mode: color-burn) {
      &.topics-bg-circles .dk-box h2 {
        background-image: url(../images/topics-bg-circles.png);
        background-blend-mode: color-burn;
      }
    }
  }

  .sc-slider h2 {
    @extend %h2-standard;
    border-bottom: rem(2) $divider-stroke solid;
    color: $text-general;

    @include mobile {
      margin: 0 1rem 1rem;
    }
  }

  @include mobile {
    section > .grid {
      padding: 0 rem(20);
    }
  }
}

.pt-research-at-niddk {
  .hero-unit {
    padding: 0;
    background: #283357 url(../images/atdk-bg-hero.jpg) no-repeat;
    background-size: cover;
    background-position: center;

    .grid > div {
      display: block;
    }

    @include mobile {
      background-position: 60% 50%;
    }
  }

  .an-research-header {
    * {
      color: $white;
      text-shadow: 0.5px 0.5px 0.5px $black;
    }

    h1 {
      margin: rem(30) 0;
      padding: 0 0 0 rem(35);
    }

    .an-division {
      display: inline-block;
      margin-left: 0.75rem;
      font-size: 1.2rem;
      font-weight: $semibold;
    }

    .an-research-information {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: rem(25) rem(35);
      background-color: rgba($white, 0.25);

      .an-research-synopsis {
        max-width: 45%;

        p {
          margin-left: 0;
          line-height: 1.8;
          font-size: 1.25rem;
          font-weight: bold;
          letter-spacing: 0.5px;
        }
      }

      .an-chief-container {
        display: flex;
        align-content: center;

        .an-chief {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-left: rem(20);
          text-align: center;

          img {
            max-width: rem(70);
            margin-bottom: 0.75rem;
            border-radius: 100%;
          }

          a {
            display: block;
            margin-bottom: 0.5rem;
            text-decoration: underline;
            line-height: 1.3;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .an-research-description {
    padding: 0 0 rem(20);

    .grid > div {
      padding: rem(25) rem(35);
      background-color: $bg-ash;
    }
  }

  .dk-flex-table {
    margin: 0;
  }

  .lab-branch-list {
    li {
      margin-bottom: 1rem;
    }
  }

  @include mobile {
    .an-research-header {
        padding: rem(15) 0 0;

        h1, .an-division {
          display: block;
          margin: 0;
          padding: 0;
          text-align: left;
        }

        h1 {
          line-height: 1.2;
          margin-bottom: 0.2rem;
        }

        .an-research-information {
          flex-direction: column;
          margin: rem(20) rem(-20) 0;
          padding: rem(20);
          text-align: left;

          .an-research-synopsis {
            max-width: 100%;
            margin-bottom: 1rem;

            p {
              margin: 0;
              font-size: 1.1rem;
              line-height: 1.75rem;
            }
          }

          .an-chief-container {
            justify-content: center;
            width: 100%;

            .an-chief {
              margin: 0 auto;
            }
          }
        }
    }

    .an-research-description {
      padding-bottom: 0;

      .grid {
        padding: 0;
        div {
          padding: rem(20);

          & > * {
            @include remove-bookend-margins;
          }
        }
      }
    }

    .dk-flex-table {
      margin: 0 rem(-10);
    }

    .lab-branch-list > div:not(:last-child) {
      padding-bottom: rem(15);
      margin-bottom: rem(20);
      border-bottom: 1px solid $divider-stroke;
    }
  }
}

.pt-branch {
  .dk-content {
    padding: rem(14) 0 0;
  }
}

// Health Information Landing Page
.pt-health-info {
  .landing-moments {
    padding: 0;

    .grid {
      padding: 0 rem(20);
    }

    .moments-grid {
      $imgsize: rem(100);

      display: grid;
      grid-template: "photo header" auto "photo moments" auto / $imgsize 1fr;
      gap: 1rem;
      padding: rem(20) rem(25) rem(15);
      background-color: $white;

      @include mobile {
        grid-template: "header photo" auto "moments moments" auto / 1fr 5rem;
        margin-top: rem(20);
      }

      .photo {
        grid-area: photo;
        align-self: center;

        img {
          display: block;
        }
      }

      .header {
        grid-area: header;

        h2, h3 {
          margin: 0;
          font-family: $merriweather;
          @extend %size-h3;
        }
      }

      .moments {
        grid-area: moments;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0 rem(10);

        .moment {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 0 solid $divider-stroke;
          border-bottom-width: 1px;
          font-family: $open-sans;

          a {
            padding: rem(5);

            &.audio {
              @extend %icon-base;

              font-size: 1.25rem;
              color: $text-dark;

              &::before {
                content: $i-audio;
              }
            }
          }
        }

        a.cta {
          grid-column: 2;
        }

        @include desktop {
          .moment {
            &:nth-of-type(-n+2) {
              border-top-width: 1px;
            }
          }

          a.cta:nth-child(even) {
            margin-top: auto;
            padding-bottom: 2px;
          }
        }

        @include mobile {
          .moment, a.cta {
            grid-column: span 2;
          }

          .moment:first-child {
            border-top-width: 1px;
          }
        }
      }
    }
  }

  .topics-row {
    .dk-box {
      $diseases: 'diabetes' 'digestive' 'kidney' 'weight' 'liver' 'urologic' 'endocrine' 'diet' 'blood';

      @each $disease in $diseases {
        $sprite-pos: map.get(sprite.$health-sprite-positions, $disease);
        $sprite-x:  list.nth($sprite-pos, 1) - 7; // Sprites are 84x84, but displayed here in 70x84 box
        $sprite-y:  list.nth($sprite-pos, 2);

        &.#{$accent-color-prefix}#{$disease} h2 {
          &::before {
            background-position: rem($sprite-x) rem($sprite-y);
          }
        }
      }

      h2 {
        @include mobile {
          padding-right: rem(70);
        }

        @include desktop-only {
          padding-right: rem(70);
        }

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          width: rem(70);
          height: rem(84);
          margin: 0; // margin and z-index counteract sticky header position adjustment
          z-index: auto;

          @extend %health-info-icon-sprite-background;

          @include tablet {
            content: normal;
          }
        }
      }
    }
  }

  .tiny-grid {
    .tiny-grid-row {
      @include neat.row;

      .tiny-grid-square {
        @include neat.span-columns(6 of 12);
        @include neat.omega;
        margin-bottom: rem(10);

        .tiny-grid-icon {
          font-size: rem(64);
          text-align: center;
          color: #1365b1;
        }

        .tiny-grid-caption {
          @include neat.row;
          margin-top: 0;
          font-family: $open-sans;
          font-weight: bold;
          font-size: rem(13);
          text-align: center;
        }
      }

      a.tiny-grid-square {
        &:hover, &:active {
          color: $text-general;
        }
      }
    }
  }
}

.pt-research-landing {
  .hero-unit {
    background: {
      color: #875459;
      image: url(../images/rf-bg-hero.jpg);
      position: center;
    }
  }
}

.pt-resources-landing {
  .hero-unit {
    background: {
      color: #6d5457;
      image: url(../images/rr-bg-hero.jpg);
      position: center;
    }
  }
}

.pt-news-landing {
  .hero-unit {
    background: {
      color: #527b8b;
      image: url(../images/news-bg-hero.jpg);
      position: center;
    }

    .thirds {
      text-align: right;
    }
  }

  .sc-news {
    margin-top: rem(-20);
  }

  .ml-feature {
    color: $white;
    font-family: $open-sans;

    h2, .cta, .cta:after, .ml-keywords > a {
      color: inherit;
    }

    h2 {
      @extend %h2-standard;
      border-bottom: rem(2) $divider-stroke solid;
    }

    .fs-search {
      margin: 1rem 0 1.5rem;

      .search-box {
        @include neat.span-columns(8);
      }

      .search-clear {
        @include neat.span-columns(4);

        span {
          width: 100%;
          font-weight: normal;
        }
      }

      @include mobile {
        position: initial;
        top: 0;
        left: 0;
        width: 100%;
        margin: 0;
        visibility: visible;
        opacity: 1;

        &:before {
          display: none;
        }

        .search-box {
          width: 100%;
          margin: 0;
          background-color: transparent;
        }

        .search-clear {
          width: 100%;
          margin: .5rem 0 1rem;
          padding: 0;
          border: 0;
          background-color: transparent;
        }
      }
    }

    .ml-keywords {
      a {
        display: inline-block;
        margin: 0 .33rem .67rem 0;
        padding: .33rem .67rem;
        border-radius: rem(3);
        background-color: rgba($black, 0.3);
        font-family: $open-sans;

        &:hover {
          background-color: rgba($black, 0.5);
          text-decoration: none;
        }
      }
    }
  }
}

.media-library-bg {
  background-position: center;
  background-size: cover;

  // Background image classes
  $bgImages: (
    '../images/ml-bg1.jpg',
    '../images/ml-bg2.jpg',
    '../images/ml-bg3.jpg',
    '../images/ml-bg4.jpg'
  );
  @for $i from 1 through list.length($bgImages) {
    $sel: if($i == 1, '&', '&.img' + $i);
    #{$sel} {
      background-image: url(list.nth($bgImages, $i));
    }
  }

  // Color overlay classes
  @for $i from 1 through list.length(map.keys($color-themes)) {
    $theme: map.get($color-themes, list.nth(map.keys($color-themes), $i));
    $alpha: list.nth($theme, 4);
    $sel: if($i == 1, '&', '&.c' + $i);
    #{$sel} > div {
      background-image: linear-gradient(180deg, #{rgba(list.nth($theme, 2), $alpha)}, #{rgba(list.nth($theme, 3), $alpha)})
    }
  }
}

.pt-media-library {
  .media-library-bg {
    margin: rem(-14) rem(-34) 0;

    @include mobile {
      margin: rem(-14) rem(-24) 0;
    }
  }

  .media-library-header {
    position: relative;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: rem(20) rem(80) rem(38);
    color: $white;
    font-family: $open-sans;

    .fs-search {
      @include desktop {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;

        .search-box {
          width: 75%
        }

        &::after {
          content: none;
        }

        .search-clear {
          width: auto;

          & > button {
            width: rem(100);
            padding: 0 0.5rem;
            margin-right: 0;
          }

          span {
            position: absolute;
            left: rem(81);
            bottom: 0;
            margin: 0.75rem 0;
          }
        }
      }
    }

    @include mobile {
      padding: rem(14) rem(24);

      .search-clear > span {
        color: $text-general;
      }
    }
  }

  .ml-detail {
    @include desktop {
      display: flex;
      flex-wrap: wrap;

      .ml-asset {
        flex: 2;
        margin-right: 2rem;
      }

      .ml-info {
        flex: 3;
      }
    }

    .dk-facet-search {
      width: 100%;

      .fs-search {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        margin: 1.5rem 0 0.5rem;
        opacity: 1;
        visibility: visible;

        &::before {
          content: none;
        }

        .search-box {
          width: 100%;

          @include mobile {
            margin: 0;
          }
        }
      }
    }

    .ml-asset {
      figure {
        margin: 1rem auto;

        & > img {
          width: auto;
          max-height: rem(400);
        }

        @include desktop {
          margin-top: 0;
        }
      }

      a.ml-download {
        width: 100%;
        border: 1px solid $button-outline;
        text-align: center;
        background-color: $button-gray;
        color: $button-text;

        &:hover {
          background-color: color.adjust($button-gray, $lightness: -5%);
          color: color.adjust($button-text, $lightness: -5%);
        }

        & > i {
          margin-left: .5rem;
          font-size: 1.25rem;
        }
      }

      span.ml-disclaimer {
        display: block;
        margin: 1rem 0;
        padding: 1rem;
        background-color: $bg-ash;
        font-family: $open-sans;
      }
    }

    .ml-keywords {
      width: 100%;

      a {
        display: inline-block;
        margin: 0 .25rem .5rem 0;
        padding: .25rem .5rem;
        border-radius: rem(3);
        background-color: $bg-gray;
        font-family: $open-sans;

        &:visited {
          color: $link-standard;
        }

        &:hover {
          color: $link-hover;
          text-decoration: none;
          background-color: color.adjust($button-gray, $lightness: -5%);
        }
      }
    }

    h1 {
      @extend %size-h2;
      width: 100%;
    }

    h2 {
      @extend %size-h3;
    }

    @include mobile {
      h1 {
        font-size: rem(21);
      }

      h2 {
        font-size: rem(18);
      }

      .ml-asset {
        margin: 0;
      }
    }
  }
}

// Healthy Moments slider
.dk-two-column .sc-slider.sc-healthy-moments {
  margin: 1rem 0;
}

.landing-hero.split {
  @extend %clearfix;
  padding: 0;
  margin-bottom: rem(20);
  overflow: hidden;

  .grid {
    position: relative;
    padding: 0 rem(20);

    & > * {
      background-color: $white;
    }
  }

  .top {
    padding: 2rem 0 rem(20);
    color: $white;
    background: #705168 url(../images/about-bg-hero.jpg) no-repeat;
    background-size: cover;
    background-position: center;

    h1, p {
      color: $white;
    }

    .overview {
      text-shadow: rem(1) rem(1) $dark-gray;
    }

    h1 {
      margin: 0 rem(10);
      padding-bottom: 1.2rem;
      font-weight: $semibold;
      font-size: rem(36);
    }

    p {
      margin: rem(10);
      font-size: rem(18);
      line-height: 1.7;
    }

    .grid {
      & > * {
        margin-top: 1rem;
      }

      & > :first-child {
        margin-top: 1rem;
        background-color: transparent;
      }
    }
  }

  @include desktop {
    .top {
      height: rem(260);
    }

    .grid > :first-child {
      @include neat.span-columns(8 of 12);
    }

    .top .grid > :last-child {
      @include neat.span-columns(4 of 12);
      float: right;
      position: relative;
      z-index: 1;
      margin-top: 0;
    }
  }
}

.director-hero {
  position: relative;
  padding: 1rem 1.5rem;
  padding-bottom: 10rem;
  margin-bottom: -9rem;
  background-color: $white;
  @extend %clearfix;

  @include mobile {
    margin-top: rem(20);
    padding-bottom: 1rem;
    margin-bottom: 0;
  }
}

.topics-stack {
  background: $white;
  border-top: rem(8) $blue solid;
  padding: 0 1rem;

  @include desktop {
    margin-bottom: -10rem;
    padding-bottom: 10rem;
  }

  @include mobile {
    padding-bottom: 1px;

    p a.button {
      padding: 0.5rem 1rem;
    }
  }

  p {
    margin: 0;
    text-align: center;
  }

  h2 {
    @extend %h2-standard;
    border-bottom: .2rem $divider-stroke solid;
    margin-bottom: 0;

    a {
      color: $link-standard;

      &:hover {
        color: $link-hover;
      }
    }
  }

  ul {
    margin: 0 0 1rem;
    padding: 0;
    list-style-type: none;
    font-size: 1rem;
    font-family: $open-sans;
    line-height: 1.3;

    li {
      position: relative;
      margin: 0;
      padding: 0;
      border-bottom: 1px $divider-stroke solid;
      text-align: left;

      &::after {
        @extend %angle-right-after;
        position: absolute;
        top: 50%;
        right: 0.5rem;
        transform: translateY(-50%);
        color: $blue;
      }
    }

    a {
      display: block;
      padding: 0.6rem rem(25) 0.6rem 0.5rem;
      text-decoration: none;
      color: $text-dark;

      &:hover, &:active {
        background-color: color.mix(#000, $white, 10%);
      }
    }
  }
}

// Director's Update
.du-banner {
  position: relative;
  min-height: rem(88);
  margin: 0.5rem 0 2rem;
  padding: 0.5rem 1rem;
  background: #2a5184 url(../images/du-bg-banner.jpg) no-repeat;
  background-size: cover;
  color: $white;
  text-shadow: 1px 1px $black;
  font-family: $open-sans;
  font-weight: $semibold;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: rem(230);
    max-width: 100%;
    height: rem(88);
    background: url(../images/du-banner-left.png) no-repeat top left;
    background-size: auto 100%;
  }

  & > * {
    display: none;
  }

  & > :first-child {
    display: block;
    position: relative;
    z-index: 1;
    margin: 0;
    //overflow: hidden;
    text-align: right;
    font-size: rem(30);
    line-height: 1.2;

    .season {
      font-size: 0.8em;
      display: inline-block;

      @at-root .dk-two-column & {
        @include neat.media(min-width $tablet-min max-width 799px) {
          display: inline-block;
          padding: 0;
        }
      }
    }
  }

  & + article {
    margin-top: -1rem;
  }

  @include desktop {
    padding-left: rem(125);

    & > :first-child .season {
      display: block;
      padding-top: rem(6);
    }
  }

  @include mobile {
    padding-top: 0.5rem + rem(88);
    background-position: 50% 0;
    background-size: 150% 100%;

    & > :first-child {
      font-size: rem(26);
    }
  }
}

.du-dir-note {
  margin: 1.5rem 0;

  & > :first-child:not(:last-child) {
    // Image container
    @include ratio(12, 7);
    margin-top: 0.5rem;
    background: #2a5184 url(../images/du-bg-image.jpg) no-repeat;
    background-size: cover;

    img {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      margin: 0;
      padding: 0;
    }
  }

  @include desktop {
    & > :first-child:not(:last-child) {
      float: left;
      margin-right: 1.5rem;
      margin-bottom: 0.5rem;
      width: math.percentage(math.div(1, 3));
    }

    & > :last-child {
      @include remove-bookend-margins(child);
    }
  }

  @include mobile {
    & > :first-child:not(:last-child) {
      max-height: rem(300);
    }
  }
}

.ratio.bg-director {
  background: #2a5184 url(../images/du-bg-image.jpg) no-repeat;
  background-size: cover;
}

.du-news-around {
  .dk-card-row {
    .dk-card > :first-child {
      @include desktop {
        @include ratio(12, 7);
        background: #2a5184 url(../images/du-bg-image.jpg) no-repeat;
        background-size: cover;

        img {
          display: block;
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }

    .dk-card-title {
      font-size: 1rem;
      font-weight: normal;
    }
  }

  .image-card {
    background-color: transparent;

    .ratio + div {
      background-color: $bg-gray;
    }
  }
}

// Biography pages
.em-right-rail {
  @include desktop {
    font-family: $open-sans;
    @include remove-bookend-margins(child);

    h2 {
      font-size: rem(18);
      padding-bottom: 0.5rem;
      border-bottom: solid 1px $divider-stroke;
    }
  }

  @include mobile {
    & > :last-child {
      margin-bottom: 0;
    }
  }
}

// Staff directory
.sd-search-box {
  margin-bottom: 2rem;

  h2 {
    margin-top: 0;
  }

  form + form {
    margin-top: 1.5rem;
  }

  .search-field {
    display: table;
    width: 100%;
    border-collapse: collapse;
    font-size: 0;

    & > * {
      font-family: $montserrat;
      color: $button-text;
    }

    & > button, & > input {
      border: 1px solid $button-outline;
      height: 2.5rem;
      font-size: rem(12.5);
      vertical-align: top;
    }

    button {
      width: rem(80);
      background: $button-gray;
      cursor: pointer;
      padding: 0;
      border: {
        left: 0;
        radius: 0 rem(3) rem(3) 0;
      }

      &:hover {
        background: color.mix(#000, $button-gray, 5%);
      }

      &:active {
        background: color.mix(#000, $button-gray, 10%);
      }
    }

    input {
      width: calc(100% - #{rem(80)});
      padding: 0 1em;
      border-radius: rem(3) 0 0 rem(3);
      appearance: none;

      &::placeholder {
        color: color.mix(#fff, $button-text, 25%);
      }
    }
  }
}

.sd-browse-buttons {
  $button-size: rem(28);

  @extend %clearfix;
  @extend %unselectable;

  %staff-browse-button {
    display: block;
    float: left;
    margin: 0 math.div($button-size, 4) math.div($button-size, 4) 0;
    padding: 0;
    width: $button-size;
    height: $button-size;
    border-radius: rem(3);
    text-align: center;
    color: $text-dark;
    font-family: $open-sans;
    font-size: 1rem;

    &.all {
      width: ($button-size * 2 + math.div($button-size, 4));
    }
  }

  button, & > a, & > span {
    @extend %staff-browse-button;
  }

  button, & > a {
    cursor: pointer;
    border: solid 1px $button-outline;
    background-color: $button-gray;
    appearance: none;

    &:visited {
      color: $text-dark;
    }

    &:hover, &:active {
      background-color: color.mix(#000, $button-gray, 10%);
      text-decoration: none;
      color: $text-dark;
    }
  }

  & > a, & > span {
    line-height: $button-size;
  }

  & > span {
    background-color: $green;
    border-color: $green;
    color: $white;
    cursor: default;
  }
}

.sd-start-over {
  margin: rem(7) 0 1rem;

  a {
    display: inline-block;
    padding: rem(4) 0.5rem;
    border: solid 1px $blue;
    border-radius: rem(3);
    font-family: $open-sans;
    font-size: 1rem;
    text-decoration: none;
    color: $text-general;

    &:visited {
      color: $text-general;
    }

    &:hover {
      background-color: $blue;
      color: $white;
      text-decoration: none;
    }

    &::before {
      @extend %icon-base;
      content: $i-angle-left;
      padding-right: 0.5rem;
      font-size: 1.2em;
      vertical-align: middle;
      transform: translateY(#{rem(-2)});
    }
  }
}

.org-chart {
  @include ratio(33, 39);

  svg {
    display: block;
    width: 100%;
    height: 100%;

    text {
      fill: $text-general;
      font-family: $merriweather;
      font-size: 28px;
    }

    .oc-box-border {
      fill: #ebebeb;
    }

    .oc-box{
      fill: $white;
    }

    a {
      text-decoration: none;

      tspan {
        fill: $link-standard;
      }

      &:hover tspan {
        fill: $link-hover;
        text-decoration: none;
      }
    }

    &:not(.no-underline) a:hover tspan {
      text-decoration: underline;
    }
  }
}

// 404 page
.p404-container {
  margin: 1.5rem 0;

  @include desktop {
    width: 80%;
    margin: 2rem auto;
  }

  .dk-site-search {
    width: 100%;
  }

  a.button:not(:last-of-type) {
    margin-right: 1rem;
  }
}

.ndm21-toolkit {
  display: grid;
  margin: 2rem 0 3rem;
  border-radius: rem(10);
  background-color: #22a0ac;
  color: $white;

  .ndm21-inner {
    display: grid;
    grid-template: 3rem auto auto 4rem / 1fr 48%;
    margin: -1rem 1rem -2rem 2rem;
    background: transparent bottom right / contain no-repeat;

    .ndm21-text {
      grid-area: 2 / 1 / span 1 / span 2;
      margin: 0 0 1rem;
      padding-right: 33%;
    }

    .ndm21-btn {
      grid-area: 3 / 1 / span 1 / span 1;
    }

    .ndm21-img {
      grid-area: 1 / 2 / span 4 / span 1;
      background: transparent bottom right / contain no-repeat;
    }

    @include mobile {
      margin-left: 1rem;

      .ndm21-text {
        padding: 0;
        font-size: rem(18);
      }

      .ndm21-img {
        grid-area: 3 / 2 / span 2 / span 1;
        background-position-y: 25%;
      }

      .button-ghost {
        padding: 1rem;
        line-height: 1.3;
      }
    }
  }

  .button-ghost {
    background-color: rgba(0, 0, 0, 0.3);

    &:hover {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
}

// Remove the top margin of the first child and bottom margin of the last child
@mixin remove-bookend-margins($target: self) {
  @if $target == self or $target == both {
    &:first-child {
      margin-top: 0;
    }
  
    &:last-child {
      margin-bottom: 0;
    }
  }
  @else if $target == child or $target == both {
    & > * {
      @include remove-bookend-margins;
    }
  }
}

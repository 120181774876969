@use "sass:list";
@use "sass:math";
@use "sass:meta";
@use "sass:string";

/// Removes the element's gutter margin, regardless of its position in the grid hierarchy or display property. It can target a specific element, or every `nth-child` occurrence. Works only with `block` layouts.
///
/// @param {List} $query [block]
///   List of arguments. Supported arguments are `nth-child` selectors (targets a specific pseudo element) and `auto` (targets `last-child`).
///
///   When passed an `nth-child` argument of type `*n` with `block` display, the omega mixin automatically adds a clear to the `*n+1` th element. Note that composite arguments such as `2n+1` do not support this feature.
///
///   **Deprecation warning**: The omega mixin will no longer take a `$direction` argument. To change the layout direction, use `row($direction)` or set `$default-layout-direction` instead.
///
/// @example scss - Usage
///   .element {
///     @include omega;
///   }
///
///   .nth-element {
///     @include omega(4n);
///   }
///
/// @example css - CSS Output
///   .element {
///     margin-right: 0;
///   }
///
///   .nth-element:nth-child(4n) {
///     margin-right: 0;
///   }
///
///   .nth-element:nth-child(4n+1) {
///     clear: left;
///   }

@mixin omega($query: block, $direction: default) {
  $table: belongs-to(table, $query);
  $auto: belongs-to(auto, $query);

  @if $direction != default {
    @include -neat-warn("The omega mixin will no longer take a $direction argument. To change the layout direction, use the direction(){...} mixin.");
  } @else {
    $direction: get-direction($layout-direction, $default-layout-direction);
  }

  @if $table {
    @include -neat-warn("The omega mixin no longer removes padding in table layouts.");
  }

  @if list.length($query) == 1 {
    @if $auto {
      &:last-child {
        margin-#{$direction}: 0;
      }
    }

    @else if contains-display-value($query) and $table == false {
      margin-#{$direction}: 0;
    }

    @else {
      @include nth-child($query, $direction);
    }
  } @else if list.length($query) == 2 {
    @if $auto {
      &:last-child {
        margin-#{$direction}: 0;
      }
    } @else {
      @include nth-child(list.nth($query, 1), $direction);
    }
  } @else {
    @include -neat-warn("Too many arguments passed to the omega() mixin.");
  }
}

@mixin nth-child($query, $direction) {
  $opposite-direction: get-opposite-direction($direction);

  &:nth-child(#{$query}) {
    margin-#{$direction}: 0;
  }

  @if meta.type-of($query) == string {
    $query: str-replace($query, " ", "");
    $operator: false;

    @if str_index($query, "+") {
      $operator: "+";
    } @else if str_index($query, "-") {
      $operator: "-";
    }

    @if $operator {
      $operator-index: str_index($query, $operator);
      $first: string.slice($query, 0, ($operator-index - 1));
      $last: to-number(string.slice($query, ($operator-index + 1), -1));
      @if $operator == "+" {
        $last: $last + 1;
      } @else if $operator == "-" {
        $last: $last - 1;
      }
      $nth: "#{$first}#{$operator}#{$last}";

      &:nth-child(#{$nth}) {
        clear: $opposite-direction;
      }
    }
  } @else if meta.type-of($query) == number and math.unit($query) == "n" {
    &:nth-child(#{$query}+1) {
      clear: $opposite-direction;
    }
  }
}

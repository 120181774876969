@use '../common' as *;

// Promo banner
.promo-banner {
  position: relative;
  width: 100%;
  overflow: hidden;
  color: $white;
  font-family: $open-sans;
  font-weight: $semibold;
  font-size: 1rem;
  line-height: 1.2;
  text-shadow: 1px 1px $black;
  border-color: $default-accent-color;
  background-color: $default-accent-color;
  margin: 1rem 0;

  & > .text {
    position: relative;
    background-color: inherit;

    & > * {
      & > * {
        margin: 0.5rem 0;
      }

      @extend %remove-child-bookend-margins;
    }

    .medium {
      @include text-scale-until-fixed(17px, 21px);
    }

    .large {
      @include text-scale-until-fixed(26px, 30px);
    }

    p {
      color: inherit;
      line-height: 1.5;
    }
  }

  img {
    width: auto;
    height: auto;
  }

  @include mobile {
    max-width: rem(600);

    & > .text {
      padding: 1rem 5%;

      &::before, &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        background-color: inherit;
      }

      &::before {
        height: rem(20);
        opacity: 0.2;
      }

      &::after {
        height: rem(5);
        opacity: 0.5;
      }

      &:first-child {
        &::before, &::after {
          top: 100%;
        }
      }

      &:last-child {
        &::before {
          top: rem(-20);
        }

        &::after {
          top: rem(-5);
        }
      }
    }

    & > .chevron {
      display: none;
    }

    img {
      display: block;
      max-width: 100%;
    }
  }

  @include desktop {
    height: rem(220);
    max-width: rem(660);

    & > .text, & > .image {
      width: 50%;
      height: rem(220);
      float: left;
    }

    & > .image {
      text-align: center;

      & > div {
        width: 150%;
        margin-left: -25%;
        text-align: center;
      }

      img {
        max-width: rem(600);
        max-height: rem(220);
      }
    }

    & > .text {
      display: table;

      &:first-child {
        padding-left: 5%;
      }

      &:last-child {
        padding: 0 3% 0 5%;
      }

      // Tablet size fix
      @include mobile-tablet {
        &:last-child {
          padding: 0 3% 0 2%;
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          width: 1px;
          background-color: inherit;
        }

        &:first-child::after {
          right: -1px;
        }

        &:last-child::after {
          left: -1px;
        }
      }

      & > * {
        display: table-cell;
        vertical-align: middle;
      }
    }

    & > .chevron {
      top: rem(-40);
      border-color: inherit;

      &, & > * {
        display: block;
        position: absolute;
      }

      &::before, &::after, & > *::before {
        content: '';
        display: block;
        position: absolute;
        border: solid transparent;
      }
    }

    & > .chevron.top {
      top: rem(-60);
    }

    & > .chevron.bottom {
      top: rem(-20);
    }

    & > .text + .chevron {
      left: 50%;

      &::before {
        top: rem(-60);
        border-width: rem(210) 0 rem(210) rem(55);
        border-left-color: inherit;
        opacity: 0.2;
      }

      &::after {
        border-width: rem(150) 0 rem(150) rem(35);
        border-left-color: inherit;
        opacity: 0.5;
      }

      & > * {
        top: rem(20);
        left: 0;
        border-color: inherit;

        &::before {
          border-width: rem(130) 0 rem(130) rem(30);
          border-left-color: inherit;
        }
      }
    }

    & > .image + .chevron {
      left: calc(50% - #{rem(30)});

      &::before {
        top: rem(-60);
        left: rem(-25);
        border-width: rem(210) rem(55) rem(210) 0;
        border-right-color: inherit;
        opacity: 0.2;
      }

      &::after {
        left: rem(-5);
        border-width: rem(150) rem(35) rem(150) 0;
        border-right-color: inherit;
        opacity: 0.5;
      }

      & > * {
        top: rem(20);
        right: 0;
        border-color: inherit;

        &::before {
          border-width: rem(130) rem(30) rem(130) 0;
          border-right-color: inherit;
        }
      }
    }
  }

  @extend %accent-border;
  @extend %accent-background;
}

// Promo hero unit
.promo-hero {
  position: relative;
  width: 100%;
  overflow: hidden;
  color: $white;
  font-size: 1rem;
  line-height: 1.2;
  text-shadow: 1px 1px $black;
  border-color: $default-accent-color;
  background-color: $default-accent-color;
  margin: 1rem 0;

  & > .text {
    position: relative;
    background-color: inherit;

    & > * {
      & > * {
        margin: 1rem 0;
      }

      @extend %remove-child-bookend-margins;
    }

    .medium, h3 {
      @include text-scale-until-fixed(17px, 21px);
    }

    .large, h1, h2 {
      @include text-scale-until-fixed(26px, 30px);
    }

    p {
      color: inherit;
      line-height: 1.5;
    }
  }

  img {
    width: auto;
    height: auto;
  }

  @include mobile {
    max-width: rem(600);

    & > .text {
      padding: 1rem 5%;

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        height: rem(5);
        background-color: inherit;
        opacity: 0.5;
      }

      &:first-child::after {
        top: 100%;
      }

      &:last-child::after {
        top: rem(-5);
      }
    }

    & > .chevron {
      display: none;
    }

    img {
      display: block;
      max-width: 100%;
    }
  }

  @include desktop {
    height: rem(270);
    max-width: rem(660);

    & > .text, & > .image {
      width: 50%;
      height: rem(270);
      float: left;
    }

    & > .image {
      text-align: center;

      & > div {
        width: 150%;
        margin-left: -25%;
        text-align: center;
      }

      img {
        max-width: rem(600);
        max-height: rem(270);
      }
    }

    & > .text {
      display: table;
      padding: 0 3% 0 5%;

      // Tablet size fix
      @include mobile-tablet {
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          width: 1px;
          background-color: inherit;
        }

        &:first-child::after {
          right: -1px;
        }

        &:last-child::after {
          left: -1px;
        }
      }

      & > * {
        display: table-cell;
        vertical-align: middle;
      }
    }

    & > .chevron {
      top: rem(-50);

      &, & > * {
        display: block;
        position: absolute;
        bottom: 0;
        background-color: inherit;
        border-color: inherit;
      }

      & > * {
        top: 0;
        width: rem(5);
        opacity: 0.5;
      }

      &::before, &::after {
        content: '';
        display: block;
        position: absolute;
        border: solid transparent;
      }
    }

    & > .chevron.top {
      top: rem(-100);
    }

    & > .chevron.bottom {
      top: 0;
    }

    & > .text + .chevron {
      left: 50%;

      &::before, &::after {
        border-width: rem(185) rem(35) rem(185) 0;
        border-top-color: inherit;
        border-bottom-color: inherit;
      }

      &::before {
        left: rem(5);
        opacity: 0.5;
      }
    }

    & > .image + .chevron {
      left: calc(50% - #{rem(35)});

      &::before, &::after {
        border-width: rem(185) 0 rem(185) rem(35);
        border-top-color: inherit;
        border-bottom-color: inherit;
      }

      &::before {
        left: rem(-5);
        opacity: 0.5;
      }

      & > * {
        left: rem(30);
      }
    }
  }

  @extend %accent-border;
  @extend %accent-background;
}

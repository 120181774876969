@use 'sass:math';
@use '../common' as *;

.steps-list {
  counter-reset: steps-list;
  @extend %reset-nested-list-styles;

  &.letters > *::before {
    content: counter(steps-list, upper-alpha);
  }

  &.square > *::before {
    border-radius: rem(5);
  }

  @each $name in get-accent-names() {
    &.#{$name} {
      --accent-bg-color: #{get-accent-color($name)};
    }
  }

  // Add non-standard blue-green color for STEP-UP
  &.#{$accent-color-prefix}blue-green {
    --accent-bg-color: #{$bg-blue-green};
  }

  &.loose {
    & > * + * {
      margin-top: 1.5rem;
    }

    ul, ol {
      &:not(.loose) > li {
        margin-top: 0.3em;
        margin-bottom: 0.3em;
      }
    }
  }

  @mixin -circle-sizing($size) {
    min-height: rem($size);
    padding-left: rem($size + 10);

    &::before {
      width: rem($size);
      height: rem($size);
      font-size: rem($size * 0.6);
    }
  }

  & > * {
    display: block;
    list-style: none;
    position: relative;
    margin: 1rem 0;
    padding: 0;

    @include -circle-sizing(40);

    &::before {
      content: counter(steps-list);
      display: block;
      position: absolute;
      left: 0;
      border-radius: 50%;
      color: $white;
      font-family: $montserrat;
      line-height: math.div(1, 0.6);
      text-align: center;
      font-style: normal;
      counter-increment: steps-list;

      @extend %unselectable;
      @extend %accent-background;
    }

    & > .title {
      font-weight: bold;
    }

    & > .bigger {
      font-size: rem(16);
    }
  }

  @include desktop {
    &.large-bullets > * {
      @include -circle-sizing(50);
    }
  }
}

// This file contains definitions for Thumbnail Lists and for the similar
// Stack Icon Blocks, which support longer content aligned next to a stack icon.
@use '../common' as *;

%_child-elts {
  margin: 0 0 0.3rem;
  line-height: 1.4;
}

%_stack-icon-block {
  display: block;
  list-style: none;
  position: relative;
  min-height: rem(50);
  margin: 1rem 0;
  padding: 0 0 0 rem(60);

  .stack-icon {
    display: block;
    position: absolute;
    left: 0;
  }

  a.detailed-download > * {
    margin-bottom: 0;
  }
}

ul, ol {
  &.thumbnail-list, &.stack-icon-blocks, & > .stack-icon-block {
    @extend %reset-nested-list-styles;

    & > li {
      & > ul, & > ol {
        margin-left: 0;
      }
    }
  }
}

.thumbnail-list > * {
  @extend %_stack-icon-block;

  & > *:not(img):not(a):not(.stack-icon), & > div > p {
    @extend %_child-elts;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & > .title {
    font-weight: bold;
  }

  & > a {
    & > *:not(img) {
      @extend %_child-elts;
    }

    &:last-child > *:not(img) {
      margin-bottom: 0;
    }
  }

  & img:first-of-type {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    max-width: rem(50);
  }

  & img:not(:first-of-type) {
    display: none;
  }
}

.stack-icon-block {
  @extend %_stack-icon-block;
}

.stack-icon-blocks > * {
  @extend %_stack-icon-block;
}

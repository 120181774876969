@use 'sass:color';
@use 'sass:math';
@use '../../lib/bourbon';
@use '../../variables' as *;
@use '../../functions' as *;
@use '../../mixins' as *;
@use '../icons' as *;
@use 'utility';

@mixin enable-forms($selector: 'form.dk-form') {
  @keyframes button-gray-throb {
    0% { background-color: $button-gray; }
    50% { background-color: color.adjust($button-gray, $lightness: -10%); }
    100% { background-color: $button-gray; }
  }

  @keyframes button-blue-throb {
    0% { background-color: $blue; }
    50% { background-color: color.adjust($blue, $lightness: -10%); }
    100% { background-color: $blue; }
  }

  #{$selector} {
    @extend %dk-form;
  }
}

%dk-form {
  font-family: $open-sans;
  font-weight: $semibold;
  $input-height: 40;

  #{bourbon.$all-text-inputs}, .output {
    display: block;
    height: rem($input-height + 14);
    padding: 0.5rem;
    border: solid 1px $divider-stroke;
    border-radius: rem(3);
    font-size: rem(18);
    font-family: $open-sans;
    line-height: 1;
    color: $text-general;
    -webkit-appearance: none;
    -moz-appearance: textfield;

    &:-moz-ui-invalid {
      box-shadow: none;
    }

    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  textarea[rows] {
    height: auto;
  }

  .validation {
    display: block;
    margin-top: rem(3);
    color: $text-red;
  }

  .helper {
    display: block;
    margin-top: rem(3);
    color: $black;
  }

  @include desktop {
    &.half-width {
      max-width: 50%;
    }
  }

  label, .label {
    &.required::after {
      content: '*';
      display: inline;
      color: $text-red;
    }

    &.blue {
      color: $text-blue;
    }
  }

  .field {
    margin: 1rem 0;

    &:not(.invalid) .validation {
      display: none;
    }

    &.text {
      label, .label {
        display: inline-block;
        position: relative;
        left: rem(6);
        bottom: rem(-8);
        max-width: 90%; //calc(95% - #{rem(18)});
        padding: 0 rem(3);
        background-color: $white;
        color: $text-blue;
      }

      &.required {
        label, .label {
          &::after {
            content: '*';
            display: inline;
            color: $text-red;
          }
        }
      }

      #{bourbon.$all-text-inputs}, .output {
        display: block;
        width: 100%;
        outline: none;
      }

      .output {
        line-height: rem($input-height);
        overflow: hidden;
        word-wrap: break-word;
        overflow-wrap: break-word;
        border-color: $text-blue;

        &:focus {
          outline: solid 1px #4d90fe;
        }

        & > * {
          display: inline-block;
          vertical-align: middle;
          line-height: 1;
        }
      }

      .validation {
        position: relative;
        margin: 0;
        border-radius: 0 0 rem(3) rem(3);
        padding: 2px rem(9);
        color: $white;
        background-color: $text-red;
        font-size: rem(12.5);

        & + .validation {
          margin-top: -1px;
        }
      }

      &.invalid {
        label {
          color: $text-red;
        }

        #{bourbon.$all-text-inputs}, .output {
          height: rem($input-height + 13);
          padding-right: rem(35);
          border-color: $text-red;
          border-bottom: 0;
          border-radius: rem(3) rem(3) 0 0;
          outline: none;

          & + .validation::before {
            content: $i-exclamation-circle;
            color: $text-red;
            position: absolute;
            top: rem(-38);
            right: rem(8);
            font-family: $dk-icons;
            font-size: 1.5rem;
          }
        }

        .validation {
          display: block;
        }

        textarea[rows] {
          height: auto;
        }
      }
    }

    &.dropdown-group {
      display: flex;

      .dropdown:not(:first-child) {
        label, label::after {
          color: transparent;
          background-color: transparent;
        }
      }
    }

    &.options {
      $size: rem(16);

      label, .label, .group {
        display: inline-block;
      }

      &.stacked {
        .label {
          display: block;
          margin-bottom: 0.25rem;
          color: $text-blue;
        }

        &.required {
          .label {
            &::after {
              content: '*';
              display: inline;
              color: $text-red;
            }
          }
        }
      }

      .label {
        margin-right: 1rem;
      }

      %dkform-radioschecks {
        position: absolute;
        left: -5000px;

        & + label {
          position: relative;
          padding: rem(3);
          padding-left: $size + rem(9);
          margin-right: 1rem;
          border: solid 1px transparent;
          border-radius: rem(3);
          cursor: pointer;
          @extend %unselectable;

          &::before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: rem(2);
            transform: translateY(-50%);
            width: $size;
            height: $size;
            border: solid 1px $button-outline;
            background-color: $white;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        &:focus + label {
          border-color: #4d90fe;
        }
      }

      // Radio buttons followed by a label
      input[type='radio'] {
        @extend %dkform-radioschecks;

        & + label::before {
          border-radius: 50%;
        }

        &:checked + label::after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: rem(2);
          transform: translateX(#{math.div($size, 4)}) translateY(-50%);
          border-radius: 50%;
          background-color: $text-blue;
          width: math.div($size, 2);
          height: math.div($size, 2);
        }
      }

      // Checkboxes followed by a label
      input[type='checkbox'] {
        @extend %dkform-radioschecks;

        & + label::before {
          border-radius: rem(3);
        }

        &:checked + label::after {
          @include icon-base;
          content: $i-check;
          position: absolute;
          top: 50%;
          left: rem(2);
          transform: translateY(-50%);
          width: $size;
          height: $size;
          text-align: center;
          line-height: math.div($size, 1rem);
          color: $text-blue;
          font-size: 1rem;
        }
      }
    }

    &.upload {
      &.invalid .validation {
        display: inline-block;
        margin-top: 1rem;
      }
    }

    .dropdown {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      select {
        display: inline-block;
        min-width: rem(90);
        height: rem($input-height + 14);
        padding: 0.5rem 1.5rem 0.5rem 0.5rem;
        border: solid 1px $divider-stroke;
        border-radius: rem(3);
        font-size: rem(18);
        font-family: $open-sans;
        line-height: 1;
        color: $text-general;
        background-color: $white;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        &.dropdown-placeholder {
          color: color.mix(#fff, $text-general, 15%);
        }
      }

      &:not(:last-child) {
        margin-right: 1rem;
      }

      &::after {
        content: $i-chevron-down;
        display: block;
        position: absolute;
        right: 0.5rem;
        top: calc(50% + rem(4));
        font-family: $dk-icons;
        font-size: 0.875rem;
        font-weight: normal;
        text-rendering: auto;
        color: $text-general;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        pointer-events: none
      }

      label {
        flex: 0;
      }
    }

    .g-recaptcha, &.g-recaptcha {
      padding-top: 1rem;
    }
  }

  .buttons {
    button {
      display: inline-block;
      font-size: 1rem;
      margin: 0.8rem 0 0.8rem rem(5);
      padding: 0.5rem 2rem;
      border: solid 1px $button-outline;
      border-radius: rem(3);
      font-family: $open-sans;
      color: $button-text;
      background-color: $button-gray;
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;

      &:hover, &:active {
        background-color: color.mix(#000, $button-gray, 5%);
      }

      &:focus {
        animation: button-gray-throb 2s linear infinite;
      }

      &.blue {
        color: $white;
        background-color: $blue;

        &:hover, &:active {
          background-color: $indigo;
        }

        &:focus {
          animation: button-blue-throb 2s linear infinite;
        }
      }

      &.large {
        padding: 1rem 4rem;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  p {
    font-family: $merriweather;
    font-weight: normal;
  }
}


@use '../common' as *;

$site-nav-transition-timing: 10ms linear 250ms;
$site-nav-transition: all #{$site-nav-transition-timing};

.skip-link {
  @include sr-only;
  @include sr-only-focusable;

  display: block;
  text-align: center;
  padding: rem(5) rem(10);
  height: auto;
  border: rem(5);
  color: $link-standard;
  background-color: $white;
  text-decoration: none;
  font-family: $open-sans;
  font-size: 1rem;

  &:active, &:focus {
    position: absolute;
    top: 0;
    left: calc(50% - #{rem(85)});
    width: rem(170);
    margin-top: 1.5rem;
    z-index: 5;
  }
}

.site-alert {
  padding: 0.5rem 0;
  background-color: $bg-red;
  color: $white;
  font-family: $open-sans;
  font-size: rem(14);
  line-height: 1.6;

  &.urgent {
    background-color: $bg-red;
    color: $white;
  }

  &.info {
    background-color: $bg-peach;
    color: $text-dark;
  }

  * {
    @extend %remove-bookend-margins;
  }

  @include mobile {
    padding: 0.5rem;
  }

  p {
    margin: 0.3rem 0;
  }

  a {
    &, &:visited {
      color: inherit;
      text-decoration: underline;
    }

    &:hover, &:active {
      color: inherit;
      text-decoration: none;
    }

    &.angle-right::after {
      margin-top: rem(-2);
    }
  }

  ul, ol {
    &.horizontal {
      display: block;
      padding: 0;

      & > li {
        display: block;
      }

      @include desktop {
        display: flex;
        flex-wrap: wrap;

        & > li {
          margin-right: 1.5rem;
        }
      }
    }
  }

  a.invert-hover, .invert-hover a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @include print { display: none; }
}

.expanding-alert {
  display: block;

  &.alert-icon::before, .expand-above::before {
    @extend %icon-base;
    content: normal;
  }

  @include desktop {
    &.alert-icon {
      position: relative;
      padding-left: rem(18);

      &::before {
        content: $i-exclamation-circle;
        display: inline-block;
        position: absolute;
        top: rem(7);
        left: 0;
        z-index: 1;
      }
    }
  }

  @include mobile {
    ul, ol {
      list-style: none;
      padding: 0.5rem 0;

      li {
        margin: 0.5rem 0;
      }
    }

    .expand-above::before {
      content: $i-exclamation-circle;
      display: inline-block;
      margin-right: 0.5rem;
    }
  }

  .expand-above {
    padding: rem(3) 0;
    cursor: pointer;

    &::after {
      @extend %icon-base;
      content: $i-arrow-up;
      display: inline-block;
      margin-left: 0.5rem;
    }
  }

  &.collapsed .expand-above::after {
    content: $i-arrow-down;
  }

  .expand-below {
    padding-top: rem(3);

    @include mobile {
      margin-top: 0.5rem;
      padding: 0.8rem 0 0.5rem;
      border-top: 1px solid $text-dark;
    }
  }

  &, .expand-below {
    &:focus {
      outline: none;
    }
  }
}

.stripe-brand {
  background-color: $white;
  padding: rem(28) 0;

  @include mobile {
    padding: 0;
    box-shadow: 0 rem(1.5) rem(16) rgba($black, 0.1);
    transition: box-shadow 150ms linear, right 0.5s;

    &.stuck {
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      z-index: 100;
      box-shadow: 0 1px rem(10) $medium-gray;

      @at-root .site-wrap.pushed & {
        right: calc(100% - #{rem(40)});
      }

      @at-root .search-overlay & {
        position: static;

        & ~ .balloon {
          height: 0;
        }
      }

      @at-root .site-wrap.subscribe-overlay & {
        z-index: 35;
      }

      & ~ .balloon {
        width: 100%;
        height: rem(56);
      }
    }
  }

  .dk-brand-wrap {
    @include desktop {
      @include dk-grid;
    }

    &::after {
      content: normal;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;

    & > :first-child:not(.dk-site-logos) {
      display: block;
      margin-right: auto;
    }
  }

  // New logo styles
  .dk-site-logos {
    display: grid;
    max-width: rem(196);
    aspect-ratio: 196 / 34;
    grid-template-columns: 30fr 7fr;
    gap: rem(11);
    align-items: center;
    margin-inline: rem(8) rem(11);

    & > *, img {
      max-width: 100%;
      max-height: 100%;
    }

    // First image is for desktop/tablet, second is for mobile
    img { display: none;}
    & > *, img:last-child { display: block; }

    @include desktop(true) {
      max-width: rem(374);
      aspect-ratio: 374 / 67;
      grid-template-columns: 100fr 21fr;
      gap: rem(29);
      margin-inline: 0 rem(29);

      img:first-child { display: block; }
      img:last-child { display: none; }
    }
  }

  // Legacy logo styles
  .dk-site-logo, .dk-site-logo-addl {
    display: inline-block;

    img {
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .dk-site-logo {
    @include desktop(true) {
      width: rem(300);
      height: rem(68);

      img:not(.desktop):not(.desktop-only) { display: none; }
    }

    @include mobile {
      width: rem(166);
      height: 100%;
      padding: rem(11) rem(8);

      img:not(.mobile) { display: none; }
    }
  }

  .dk-site-logo-addl {
    margin-left: 1.2rem;

    img {
      display: none;
    }

    @media screen and (min-width: 810px) {
      width: rem(176);
      height: 100%;

      img {
        display: block;
      }
    }
  }

  .dk-site-search {
    position: relative;
    z-index: 35;

    @include print {
      display: none;
    }
  }

  .dk-site-mobile-toolbar {
    display: table;
    table-layout: fixed;
    border-collapse: collapse;
    height: rem(56);
    font-size: 0;

    @include desktop(true) {
      display: none;
    }

    button {
      display: table-cell;
      cursor: pointer;
      border: solid 0 $divider-stroke {
        width: 0 0 0 1px;
      }
      text-align: center;
      height: rem(56);
      min-width: rem(66);
      color: $text-dark;
      background: $white;
      font-family: $open-sans;
      font-size: 0.8rem;
      vertical-align: middle;

      i {
        display: block;
        font-size: 1.6rem;
        line-height: 1.2;
        color: $text-general;
      }

      &:last-child {
        border-right: 0;
      }
    }
  }
}

@include mobile {
  .dk-language-dropdown button.ss-options {
      margin-bottom: rem(17);
  }
}

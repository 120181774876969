@use '../lib/neat';
@use '../common' as *;

%split-result-item-list {
  & > li {
    list-style: none;
    margin: 0;
    padding: rem(10);
    font: normal #{rem(14)} $merriweather;

    &:nth-child(2n+1) {
      background-color: $bg-gray; // #f2f2f2
    }

    &:first-child:last-child {
      background-color: transparent;
    }

    .top {
      padding-bottom: 0.3rem;
      border-bottom: solid 1px $button-outline;
      font-family: $open-sans;

      .right {
        float: right;
      }

      h3, h4 {
        margin: 0;
        font-weight: bold;
      }
    }

    h3, h4 {
      margin: 0.5rem 0;
      font: inherit;
    }

    ul {
      list-style-type: disc;
      margin: 0.3em 0;

      ul {
        list-style-type: circle;
      }
    }

    & > div {
      margin: 0.3em 0;

      p {
        line-height: 1.5;
        margin: 0.3em 0;
      }
    }
  }
}

ol, ul {
  &.result-items {
    @extend %split-result-item-list;
  }
}

table.result-items {
  margin: 1rem 0;

  &, thead, tbody, tfoot, caption, tr, th, td {
    display: block;
    border: 0;
  }

  thead, tfoot {
    position: absolute;
    top: -5000px;
    left: -5000px;
    z-index: -100;
  }

  caption {
    margin: 0 0 1rem;
  }

  tr {
    padding: 0.5rem;
  }

  tr:nth-child(2n+1) {
    background-color: $bg-gray; // #f2f2f2
  }

  tr:first-child:last-child {
    &, td {
      background-color: transparent;
    }
  }

  td[data-prefix]::before {
    content: attr(data-prefix) ': ';
  }
}

.result-items.staff {
  line-height: 1.5;

  tr {
    @extend %clearfix;
    @include no-margin-collapse;
    padding-bottom: 0;
  }

  td {
    clear: both;
    margin: 0 0 0.5rem;
    padding: 0;

    &:empty {
      display: none;
    }
  }

  .criteria {
    font-weight: bold;
  }

  .term {
    &::before {
      content: '‘';
    }

    &::after {
      content: '’';
    }
  }

  .name, .email, .phone {
    font-family: $open-sans;
  }

  .name {
    font-weight: bold; //$semibold;
  }

  .email, .phone {
    float: left;
    clear: none;
    margin-top: 0;

    a::before {
      @extend %icon-base;
      @extend %icon-hover-fix;
      padding-right: 0.5rem;
      color: $text-general;
    }
  }

  .email {
    min-width: 35%;
    padding-right: 2rem;

    a::before {
      content: $i-envelope-o;
      font-size: 0.9em;
    }
  }

  .phone a::before {
    content: $i-mobile;
  }

  .role {
    padding-left: 1rem;
    text-indent: -1rem;

    ul {
      list-style: none;
      margin: 0;
    }

    li {
      margin: 0;
      text-indent: -1rem;
    }

    * {
      text-indent: 0;
    }

    p {
      @extend %remove-bookend-margins;
      margin: 0.5 0;
    }
  }
}

.result-items.health-resources {
  li {
    padding: 1rem rem(10);
  }

  h3 {
    font-family: $open-sans;
    font-weight: bold;
    border-bottom: solid 1px $button-outline;
    padding-bottom: 0.3rem;
    margin: 0 0 0.3rem;

    .file-size {
      font-weight: normal;

      &::before {
        content: '(';
      }

      &::after {
        content: ')';
      }
    }

    .i-audio, .i-video {
      &::before {
        font-size: 1.4em;
        vertical-align: top;
      }
    }

    i {
      margin-left: 0.3em;
    }
  }
}

.result-items.funding-opportunities {
  .top a {
    float: right;
  }

  .bottom {
    &::before {
      content: '(';
    }

    &::after {
      content: ')';
    }
  }
}

.result-items.research-materials {
  p, div:not(.top) {
    margin: 0.3rem 0;
    line-height: 1.5;

    label, .label {
      font-weight: bold;
    }
  }

  div > p {
    @extend %remove-bookend-margins;
  }

  .bottom {
    &::before {
      content: '(';
    }

    &::after {
      content: ')';
    }
  }
}

.result-items.activity-codes {
  h3, h4 {
    margin: 0;
    font-weight: bold;
  }

  p, div {
    margin: 0.3rem 0;
    line-height: 1.75;
  }
}

.result-items.itp-locations {
  h3 {
    font-weight: bold;
    border-bottom: solid 1px $button-outline;
    padding-bottom: 0.3rem;
    margin-bottom: 0.3rem;
  }

  label, .label {
    font-weight: bold;
  }

  li > div {
    margin: 0.3rem 0;
    line-height: 1.5;

    @include desktop {
      .row {
        @include neat.row;

        & > * {
          display: block;
          float: left;
        }

        & > :nth-child(2n) {
            max-width: 7rem;
            margin-left: 1rem;
        }

        & > :nth-child(2n+1) {
            width: calc(100% - 8rem);
        }
      }
    }
  }
}

.result-items.healthy-moments {
  & > li time {
    display: block;
    margin: 0.3em 0;
    font-family: $open-sans;
  }

  .audio-pill {
    margin: 0.5rem 0;
  }
}

.result-items.youtube {
  .i-video {
    position: relative;
    top: 1px;
    color: $youtube-red;
    font-size: 1.3em;
    vertical-align: middle;
    line-height: 1;
  }
}

.result-items.meetings {
  .bottom {
    margin-top: 1rem;
    font-family: $open-sans;

    span {
      display: inline-block;
      line-height: 1.7;
    }

    span:not(:last-child) {
      margin-right: 2rem;
    }
  }

  .date::before {
    @extend %icon-base;
    content: $i-calendar-o;
    margin-right: 0.5em;
  }

  .location::before {
    @extend %icon-base;
    content: $i-map-marker;
    margin-right: 0.5em;
  }

  .webinar::before {
    @extend %icon-base;
    content: $i-webinar;
    margin-right: 0.5em;
  }
}

.result-items.news {
  .top {
    padding-bottom: 0.75rem;
    @extend %news-dateline;
  }

  h3 {
    font-family: $open-sans;
    font-weight: bold;
  }
}

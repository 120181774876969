@use 'sass:color';
@use 'sass:math';
@use '../lib/bourbon';
@use '../lib/neat';
@use '../common' as *;

.fs-mobile-tools {
  $outline-color: #cfcfcf;

  @include desktop {
    display: none;
  }

  display: table;
  border-spacing: rem(10) 0;
  border-collapse: separate;
  width: 100%;

  & > * {
    display: table-cell;
    vertical-align: middle;

    button {
      position: relative;
      width: 100%;
      height: 2.5rem;
      border: 1px solid $outline-color {
        radius: rem(3);
      }
      padding: 0 rem(10);
      background-color: $white;
      font: {
        weight: $semibold;
        family: $open-sans;
        size: rem(14);
      }
      color: $button-text;
      cursor: pointer;

      & > span {
        display: block;
        position: relative;
        text-align: center;
        padding-right: rem(20);
        white-space: nowrap;
        overflow: hidden;

        &::after {
          @extend %icon-base;
          content: $i-angle-down;
          display: block;
          position: absolute;
          right: 0;
          top: calc(50% - #{rem(12.5)});
          padding: rem(5) 0 rem(5) rem(10);
          color: $link-standard;
          background-image: linear-gradient(to right, #{rgba($white, 0)}, $white 50%);
        }
      }
    }
  }

  .search {
    width: rem(60);
  }

  .summary {
    font-family: $open-sans;
    text-align: center;

    .i-exclamation-circle {
      color: #c22;
      padding-top: rem(2);
    }
  }

  .filters {
    text-align: right;
    width: rem(105);

    em.count {
      font: {
        style: normal;
        weight: normal;
      }
      color: $text-green;

      &::before {
        content: '(';
      }

      &::after {
        content: ')';
      }
    }
  }
}

.dk-facet-search {
  $button-gray: #efefef;
  $bg-gray: $button-gray;
  $bg-ash: #fbfbfb;

  .fs-search {
    &:focus {
      outline: none;
    }

    @include desktop {
      @include neat.row;
      margin: 1rem 0 2rem;

      .search-box {
        @include neat.span-columns(7);
      }

      .search-clear {
        @include neat.span-columns(5);
        @include neat.row;
      }
    }

    .search-box {
      @extend %standard-search-box;
    }

    .search-clear {
      span {
        @include neat.span-columns(4 of 6);

        font: {
          weight: $semibold;
          size: rem(12);
          family: $open-sans;
        }
      }
      button {
        @include neat.span-columns(2 of 6);
        font-family: $montserrat;
        color: $button-text;
        height: 2.5rem;
        font-size: rem(12.5);
        background: $white;
        cursor: pointer;
        padding: 0 rem(4);
        border: 1px solid $button-outline;
        border-radius: rem(3);

        &:hover {
          background: $button-gray;
        }

        &:active {
          background: color.mix(#000, $button-gray, 5%);
        }
      }
    }

    @include mobile {
      position: absolute;
      top: rem(-10);
      left: rem(10);
      z-index: 55;
      visibility: hidden;
      opacity: 0;
      width: calc(100% - #{rem(20)});
      background-color: $white;
      border-radius: rem(5);
      transition: z-index 0.2s linear;
      transition: visibility 0.2s linear;

      &.shown {
        opacity: 1;
        visibility: visible;
        transition: z-index 0.2s linear;
        transition: visibility 0.2s linear;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: rem(-10);
        left: rem(20);
        z-index: 55;
        width: 0;
        height: 0;
        border: {
          left: solid rem(10) transparent;
          right: solid rem(10) transparent;
          bottom: solid rem(10) $white;
        }
      }

      .search-box {
        background-color: $white;
        width: calc(100% - #{rem(20)});
        margin: rem(10);
      }

      .search-clear {
        @include neat.row;
        background-color: $bg-gray;
        border-top: solid 1px $button-outline;
        border-radius: 0 0 rem(5) rem(5);
        padding: rem(10);
      }
    }
  }

  .expand-group { //fieldset
    margin: 0;
    border: 0;
    padding: 0;

    &.sort + * {
      margin-top: 1rem;
    }

    .expand-title {
      display: block;
      position: relative;
      width: 100%;
      margin: 0;
      border: solid $button-outline;
      border-width: 1px 1px 0;

      button {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        border: 0;
        padding: 0 rem(5);
        margin: 0;
        background: transparent;
        appearance: none;
        font-size: 0;
        text-align: right;
        cursor: pointer;

        &::after {
          @extend %icon-base;
          content: $i-angle-down;
          font-size: 1rem;
        }
      }

      & + .options {
        display: none;
      }

      &.opened {
        button::after {
          content: $i-angle-up;
        }

        & + .options {
          display: block;
        }
      }

      @include mobile {
        border-width: 1px 0 0;
      }
    }
  }

  .filters .expand-title {
    padding: 0.7rem;
    padding-right: calc(1em + #{rem(5)});
    background-color: $white;
    font-size: rem(13);
    font-weight: $semibold;
  }

  .options {
    margin: 0;
    border: 1px solid $button-outline {
      width: 1px 1px 0;
    }

    @include mobile {
      border-width: 1px 0 0;
    }

    &:focus {
      outline: none;
    }

    li {
      list-style: none;
      margin: 0;
      padding: 0;
      background-color: $bg-ash;
      border-top: solid 1px $button-outline;
      font-size: rem(12);

      &:first-child {
        border-top: 0;
      }
    }

    $ipt-glyph-size: rem(16);

    li {
      & > .count.count.count {
        display: none;
      }

      & > label, & > .label {
        display: block;
        position: relative;
        min-height: rem(35);
        padding: 0.7rem rem(36) 0.7rem 1rem;
        font-weight: $semibold;
        line-height: 1.2;
        cursor: pointer;
        @extend %unselectable;

        &::before, &::after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          right: rem(10);
        }

        &::before {
          width: $ipt-glyph-size;
          height: $ipt-glyph-size;
          border: solid 1px $button-outline;
          border-radius: rem(3);
          background-color: $white;
          transform: translateY(-50%);
        }
      }

      %fs-opt-radio-label-after {
        &::before {
          border-radius: 50%;
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          right: rem(10);
          transform: translateX(-50%) translateY(-50%);
          border-radius: 50%;
          background-color: $text-green;
          width: math.div($ipt-glyph-size, 2);
          height: math.div($ipt-glyph-size, 2);
        }
      }

      %fs-opt-check-label-after {
        &::before {
          border-radius: rem(3);
        }

        &::after {
          @include icon-base;
          content: $i-check;
          width: $ipt-glyph-size;
          transform: translateX(-1px) translateY(-50%);;
          text-align: center;
          color: $text-green;
          font-size: 1rem;
        }
      }

      input:focus + label {
        outline: solid 2px rgba($blue, 0.5);
      }

      & > input {
        position: absolute;
        z-index: -500;
        left: -5000px;

        &:checked + label {
          color: $text-green;
        }

        &[type=radio] {
          & + label::before {
            border-radius: 50%;
          }

          &:checked + label::after {
            @extend %fs-opt-radio-label-after;
          }
        }

        &[type=checkbox]:checked + label::after {
          @extend %fs-opt-check-label-after;
        }
      }

      & > .label {
        &.radio::before {
          border-radius: 50%;
        }

        &.radio.checked::after {
          @extend %fs-opt-radio-label-after;
        }

        &.checkbox.checked::after {
          @extend %fs-opt-check-label-after;
        }
      }

      .tic-tacs {
        @extend %clearfix;
        position: relative;
        padding: 0 0.5rem 0.5rem;

        &:first-child {
          padding-top: 0.5rem;
        }

        @include neat.media(min-width $tablet-min max-width $variable-layout-max) {
          padding: rem(3);
        }

        input {
          position: absolute;
          z-index: -500;
          left: -5000px;
        }

        input + label {
          display: block;
          float: left;
          border: solid 1px $divider-stroke;
          border-radius: rem(5);
          padding: rem(3) rem(5);
          margin: rem(5) rem(3);
          background-color: $white;
          line-height: 1;
          font-weight: $semibold;
          cursor: pointer;
          @extend %unselectable;

          &:hover {
            background-color: color.adjust($text-green, $lightness: 50%);
            color: $text-dark;
          }
        }

        input:checked + label {
          border-color: $text-green;
          background-color: $text-green;
          color: $white;
        }

        .count.count {
          display: none;
        }

        &.years label {
          min-width: rem(40);
          text-align: center;
        }
      }

      &.sub-opts {
        & > input:not(:checked) {
          & + label .count {
            display: none;
          }

          & ~ .tic-tacs[data-hide-inactive] {
            display: none;
          }
        }
      }
    }
  }

  .fs-body {
    @include neat.row;
    font-family: $open-sans;

    .fs-facets {
      @include mobile {
        position: absolute;
        top: rem(-10);
        right: rem(10);
        z-index: 55;
        visibility: hidden;
        opacity: 0;
        padding: rem(40) 0 rem(10);
        max-width: calc(100% - #{rem(20)});
        width: 75vw;
        background-color: $bg-gray;
        border-radius: rem(5);
        transition: z-index 0.2s linear;
        transition: visibility 0.2s linear;

        &.shown {
          opacity: 1;
          visibility: visible;
          transition: z-index 0.2s linear;
          transition: visibility 0.2s linear;
        }

        &:focus {
          outline: none;
        }

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: rem(-10);
          right: rem(42);
          z-index: 55;
          width: 0;
          height: 0;
          border: {
            left: solid rem(10) transparent;
            right: solid rem(10) transparent;
            bottom: solid rem(10) $bg-gray;
          }
        }
      }

      @include desktop {
        @include neat.span-columns(3);
      }

      // Blue theme
      &.theme-blue {
        .options li {
          background-color: $ln-current-child;
        }

        .filters h3 {
          background-color: $ln-current;
          color: $text-dark;
        }
      }

      // Checkboxes and radio buttons on left
      &.left-inputs {
        .options li label {
          padding-left: rem(36);
          padding-right: 1rem;

          .ipt, input {
            left: rem(10);
            right: auto;
          }
        }
      }

      .panel-title {
        background-color: $bg-gray;
        border: 1px solid $button-outline {
          bottom-width: 0;
        }
        padding: rem(6);
        font-size: rem(13);

        label {
          font-family: $montserrat;
        }

        span {
          color: $text-green;
          font-weight: $semibold;
        }
      }

      & > div {
        margin: 1rem 0;

        &:first-child {
          margin-top: 0;
        }
      }

      .sort {
        .panel-title {
          position: relative;
          cursor: pointer;
          padding: {
            right: calc(1em + #{rem(5)});
            top: rem(10);
            bottom: rem(10);
          }
          border-width: 1px;

          label {
            cursor: pointer;
          }

          @include mobile {
            border-width: 1px 0 1px;
            background-color: $white;
          }
        }

        .options {
          border-top-width: 0;
          border-bottom-width: 1px;
        }
      }

      .filters {
        border-bottom: 1px solid $button-outline;

        @include mobile {
          margin-bottom: 0;
        }

        .panel-title {
          @include neat.row;

          label {
            line-height: 2rem;
          }

          button {
            font-family: $montserrat;
            float: right;
            width: rem(70);
            color: $button-text;
            height: 2rem;
            font-size: rem(12.5);
            background: $white;
            cursor: pointer;
            padding: 0;
            border: 1px solid $button-outline;
            border-radius: rem(3);

            &:hover {
              background: $button-gray;
            }

            &:active {
              background: color.mix(#000, $button-gray, 5%);
            }
          }

          @include mobile {
            position: absolute;
            top: 0;
            width: 100%;
            border: 0;
            background-color: transparent;
          }
        }

        .options .count {
          display: inline-block;
          font-size: 0.8em;
          vertical-align: middle;
          color: $text-general;

          &::before {
            content: '(';
          }

          &::after {
            content: ')';
          }
        }

        .options label[aria-checked="false"] .count {
          display: none;
        }
      }
    }

    .fs-results {
      @include desktop {
        @include neat.span-columns(9);
      }

      .summary {
        position: relative;
        padding: rem(10) rem(6);
        margin: 0 0 1rem;
        border: 1px solid $button-outline;
        font-size: rem(13);
        background-color: $white;
        box-shadow: 0 0 0 $bg-gray;
        transition: box-shadow 500ms linear;
        z-index: 1;

        @include mobile {
          display: none;
        }

        &.stuck {
          box-shadow: 0 1px rem(10) $medium-gray;
          transition: box-shadow 500ms linear;
          cursor: pointer;

          &::before {
            content: '';
            position: absolute;
            top: -1px;
            left: 0;
            right: 0;
            border-bottom: 1px solid $button-outline;
          }

          &::after {
            content: '';
            display: block;
            position: absolute;
            left: rem(-10);
            right: rem(-10);
            top: rem(-71);
            height: rem(70);
            background: $white;
            opacity: 1;
          }
        }

        &.can-clear {
          padding-right: rem(80);

          .clear-all {
            display: block;
          }
        }

        .line-clamp {
          @include line-clamp-grow(3, 13, 1.4);
        }

        .terms, .filters {
          font: {
            style: normal;
            weight: $semibold;
            size: rem(13);
          }
          line-height: inherit;
          color: $text-green;
        }

        ul.filters {
          display: inline;

          li {
            list-style: none;
            display: inline;
            margin: 0;
            padding: 0;

            &:not(:last-child)::after {
              content: ', ';
            }
          }
        }

        .i-exclamation-circle {
          color: #c22;
          vertical-align: middle;
        }

        .clear-all {
          display: none;
          position: absolute;
          top: 50%;
          right: rem(6);
          width: rem(68);
          margin: 0;
          padding: rem(5) 0;
          border: 1px solid $button-outline;
          border-radius: rem(3);
          font-family: $montserrat;
          font-size: rem(12.5);
          line-height: 1.28;
          color: $button-text;
          background: $white;
          transform: translateY(-50%);
          cursor: pointer;
          -webkit-appearance: none;

          &:visited, &:hover, &:active {
            text-decoration: none;
            color: inherit;
          }

          &:hover {
            background: $button-gray;
          }

          &:active {
            background: color.mix(#000, $button-gray, 5%);
          }
        }
      }
    }
  }
}

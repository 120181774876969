@use 'sass:color';
@use '../common' as *;

.usrds-header {
  border-color: $indigo;
}

.usrds-logo {
  display: block;
  width: rem(270);
  max-width: 60vw;
  height: rem(100);
  margin: 0 1.5rem 0 1.5rem;
  padding: 1.5rem 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
  font-size: 0;
  text-indent: 5000rem;
}

.usrds-home-columns {
  .two-thirds {
    @extend %remove-child-bookend-margins;
  }

  .box {
    margin-bottom: 0;
  }

  @include mobile {
    & > * + * {
      margin-top: 2rem;
    }

    .box.usrds-contact {
      margin: 0;
    }
  }
}

.usrds-home-grid {
  display: grid;
  margin: 2rem 0;
  gap: 2rem 1rem;

  @include desktop(true) {
    grid-template-columns: 1fr 1fr;

    // Ensure that the last item spans both columns if there is an odd number
    & > :nth-child(odd):last-child {
      grid-column: span 2;
    }
  }

  .box {
    margin: 0;
  }

  .box-content {
    @extend %remove-child-bookend-margins;

    &, p {
      line-height: $lh-lists;
    }
  }

  .usrds-home-publication {
    img {
      display: block;
      float: left;
      width: 45%;
      max-width: rem(150);
      margin: rem(4) 1rem 0.5rem 0;
    }
  }
}

.usrds-content {
  iframe {
    max-width: 100%;
  }
}

.box.usrds-contact {
  margin: 1.5rem 0;

  .extra-margin {
    margin: 1.5rem 0;
  }
}

.usrds-contact-full, .usrds-contact-mobile {
  display: none;
}

@include desktop {
  .usrds-contact {
    max-width: rem(285);
  }

  .usrds-contact-full {
    display: block;

    &.float {
      float: right;
      min-width: rem(285);
      margin-left: 1rem;
    }

    .box.usrds-contact {
      margin-top: 0;
    }
  }
}

@include mobile {
  .usrds-contact-mobile {
    display: block;
  }
}

// News page

%_read-more {
  margin: 1rem 0 0;

  a {
    @extend %button;
    margin: 0;
    padding: 0.5rem 1rem;

    &::after {
      @extend %angle-right-after;
      transform: translateY(-1px);
      padding-left: 0.35rem;
    }
  }
}

.usrds-news-feature-grid {
  display: grid;
  gap: 1rem;

  @include desktop {
    grid-template-columns: 2fr 1fr;

    & > :nth-child(odd):last-child {
      grid-column: span 2;
    }
  }
}

.usrds-news-item-feature {
  position: relative;
  border: #{rem(10)} solid $button-gray;
  padding: 1rem;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: 0 0 rem(5) rem(2) color.mix(#000, $button-gray, 5.5%);
  }

  h2 {
    margin: 0 0 1rem;
    padding: 0 0 1rem;
    border-bottom: 1px solid $divider-stroke;
    color: $text-dark;
    @extend %size-h3;
  }

  img {
    display: block;
    float: left;
    width: 45%;
    max-width: rem(150);
    margin: rem(4) 1rem 0.5rem 0;
  }

  .box-content, .read-more {
    position: relative;
  }

  .read-more {
    @extend %_read-more;
  }
}

.usrds-news-interrupter {
  display: flex;
  align-items: center;
  background-color: $bg-gray;

  .action-link {
    margin: 0;
  }
}

.usrds-news-date-filter {
  @extend %news-horizontal-date-links;
}

.usrds-news-list {
  margin: 1rem 0 3rem;
}

.usrds-news-list-item {
  @extend %clearfix;

  & + & {
    margin-top: 1.5rem;
    border-top: 2px solid $divider-stroke;
  }

  .usrds-news-image {
    margin: 1rem 0;
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: rem(150);
  }

  h2 {
    @extend %size-h3;
  }

  time {
    display: block;
    margin: 1rem 0 0.5rem;
    font-family: $open-sans;
    font-weight: $semibold;
  }

  .text {
    @extend %remove-child-bookend-margins;

    font-size: 1rem;
    line-height: 1.8;

    img, figure, .figure, table {
      display: none;
    }
  }

  .read-more {
    @extend %_read-more;
    clear: both;
  }

  @include desktop {
    .usrds-news-image {
      float: left;
      width: rem(150);
      margin: 0.5rem 1rem 1rem 0;
    }
  }
}

@use 'sass:math';
@use '../common' as *;

.nav-tiles {
  display: grid;
  margin: 2rem 0;
  gap: 1rem;

  @include desktop(true) {
  grid-template-columns: repeat(4, 1fr);  }
}

.nav-tile {
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden;
  border-radius: rem(3);
  box-shadow: $tile-shadow;
  background-image: linear-gradient(135deg, $indigo, $blue);
  font-family: $open-sans;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;

  &::before {
    content: '';
    display: block;
    height: rem(120);
    background-image: inherit;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;

    @include desktop {
      height: 0;
      padding-top: math.percentage(math.div(1, 2));
    }
  }

  & > * {
    display: block;
    margin: 0;
    padding: 0 0.5rem 0.8rem;
    background-color: $white;
  }

  & > :last-child {
    flex: 1 0 auto;
  }

  a.nav-tile-title::before,
  .nav-tile-title a:first-of-type::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.nav-tile-title {
  padding-top: 0.8rem;
  font-weight: $semibold;
}

.nav-tile-text {
  @extend %remove-child-bookend-margins;

  p {
    line-height: normal;
  }
}

@use 'sass:color';
@use 'sass:list';
@use 'sass:map';
@use 'sass:math';
@use '../../lib/neat';
@use '../../common' as *;
@use 'health-info-icon-sprite' as sprite;

// Clickable arrow graphic, such as the RTCD Funding Eligibility by Career Level graphic
.rtcd-career-arrow {
  $arrow-min: 820px;

  @include arrow-graphic(
    $arrow-min: $arrow-min,
    $colors: #35726B #037086 #1968E0 #4D4378 #A93B51);

  & > * > * > * > * {
    max-width: calc(100% + 5px);
  }

  @include neat.media(max-width $arrow-min - 1) {
    br {
      display: none;
    }
  }
}

// Health Professional topic icons
.hp-topic-icon {
  display: block;
  position: relative;
  width: rem(90);
  height: rem(90);
  margin: 0 auto;
  border: rem(5) solid $bg-outer-body;
  border-radius: 100%;
  overflow: hidden;
  text-indent: -5000rem;
  @extend %accent-background;

  @supports (background-blend-mode: color-burn) {
    background-image: url(../images/topics-bg-circles.png);
    background-blend-mode: color-burn;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: rem(-2);
    left: rem(-2);
    width: rem(84);
    height: rem(84);
    margin: 0 auto;

    @extend %health-info-icon-sprite-background;
  }

  @at-root a:hover & {
    @extend %accent-background-darken;
  }

  $diseases: (
    'diabetes':        'sea',
    'digestive':       'bondi',
    'digestive-liver': 'bondi',
    'kidney':          'orchid',
    'weight':          'gold'
  );

  @each $disease, $data in $diseases {
    $name: list.nth($data, 1);
    $sprite-pos: map.get(sprite.$health-sprite-positions, $disease);
    $sprite-x:  list.nth($sprite-pos, 1);
    $sprite-y:  list.nth($sprite-pos, 2);

    &.#{$disease} {
      @extend %accent-color-#{$name};

      &::after {
        background-position: rem($sprite-x) rem($sprite-y);
      }
    }
  }
}

.hp-topic-icon-grid {
  display: flex;
  position: relative;
  justify-content: space-around;

  & > * {
    max-width: 49%;
    text-align: center;
  }

  .hp-topic-icon + * {
    margin-top: 0.3rem;
  }

  .hp-topic-icon ~ * {
    display: block;
  }

  @include mobile {
    flex-wrap: wrap;

    & > * {
      width: 50%;
    }

    & > :nth-child(n+3) {
      margin-top: 1.5rem;
    }
  }
}

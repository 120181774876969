@use 'sass:color';
@use '../lib/neat';
@use '../common' as *;

.dk-tabs {
  $scroll-left-button-shadow: rem(5) 0 rem(4) rem(-2) $light-gray;
  $scroll-right-button-shadow: rem(-5) 0 rem(4) rem(-2) $light-gray;
  $border-radius: rem(4);

  margin: 1rem 0;

  &.dk-scrollable {
    padding-top: rem(1);
    background-color: color.adjust($divider-stroke, $lightness: 10%);
    border: {
      top-left-radius: $border-radius;
      top-right-radius: $border-radius;
      width: 1px;
      style: solid;
    }
    @extend %accent-border;

    div.scroll_tab_inner {
      span:not([class*="_finisher"]):not(.tab_selected):not([aria-selected=true]) {
        border: {
          right: 1px solid $divider-stroke;
          left: 0;
          top-color: color.adjust($divider-stroke, $lightness: 10%);
          radius: 0;
        }
        margin-right: 0;
      }
    }
  }

  .scroll_tab_inner i {
    font-style: normal;
  }

  .scroll_tabs_container {
    margin: 0;
    position: relative;
    top: rem(1);

    // Begin tab button styling
    div.scroll_tab_left_button, div.scroll_tab_right_button {
      background-color: color.adjust($divider-stroke, $lightness: 10%);
      height: calc(100% - 1px);
      z-index: 10;
      position: absolute;

      &::before {
        @extend %icon-base;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        padding: 0;
        font-size: rem(18);
      }

      &:not(.scroll_arrow_disabled)::before {
        color: $black;
      }

    }

    div.scroll_tab_left_button {
      box-shadow: $scroll-left-button-shadow;
      border: {
        top-left-radius: $border-radius;
        bottom-left-radius: $border-radius;
      }

      &::before {
        content: $i-angle-left;
      }
    }

    div.scroll_tab_right_button {
      box-shadow: $scroll-right-button-shadow;
      border: {
        top-right-radius: $border-radius;
        bottom-right-radius: $border-radius;
      }

      &::before {
        content: $i-angle-right;
      }
    }
    // end

    // Begin tab styling
    div.scroll_tab_inner {

      span:not([class*="_finisher"]) {
        background-color: $bg-gray;
        height: 100%;
        font: {
          family: $open-sans;
          weight: $semibold;
          size: 1rem;
        }
        border: {
          width: 1px rem(.5);
          style: solid;
          top-left-radius: $border-radius;
          top-right-radius: $border-radius;
        }
        margin-right: rem(1);

        @extend %accent-border;

        &.tab_selected, &[aria-selected=true] {
          box-shadow: inset 0 rem(20) 0 rem(-17) $default-accent-color;
          box-shadow: inset 0 rem(20) 0 rem(-17) var(--accent-border-color, #{$default-accent-color});
          background-color: $white;
          border-bottom: 1px solid $white;

          &.scroll_tab_over {
            background-color: $white;
            cursor: default;
          }
        }

        &.scroll_tab_over {
          background-color: color.mix(#000, $bg-gray, 10%);
        }
      }
    }
    // end
  }

  .dk-tabs-content {
    & > div {
      @include neat.row;
      display: none;
      background-color: $white;
      height: 100%;
      margin: 0;
      padding: rem(20);
      border: {
        style: solid;
        width: 1px 0 0;
      }

      @extend %accent-border;
      @extend %remove-child-bookend-margins;

      &.show {
        display: block;
      }
    }
  }

  &:not(.dk-scrollable) .dk-tabs-content > div {
    border-width: 1px;
  }
}

@use 'sass:math';

@mixin ratio($wide, $tall) {
  display: block;
  position: relative;
  max-width: 100%;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    width: 100%;
    padding-top: math.percentage(math.div($tall, $wide));
  }

  & > * {
    position: absolute;
  }
}

@use 'sass:color';
@use 'sass:list';
@use 'sass:math';
@use '../common' as *;

$_size-small: 28px;
$_size-large: 40px;

@mixin _share-button-size($size: 28px, $border: 2px) {
  $font-ratio: 2;
  $font-size: math.div($size, $font-ratio);

  width: rem($size);
  height: rem($size);
  max-height: rem($size);
  border-width: $border;

  &::before {
    font-size: rem($font-size);
  }
}

%_share-button {
  @include icon-base;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  border-style: solid;
  border-radius: 50%;
  background-color: $white;
  font-size: 0;
  appearance: none;
  cursor: pointer;

  @extend %accent-foreground;
  @extend %accent-border;
  @include _share-button-size;

  $icons: (
    copy: $i-copy,
    email: $i-envelope,
    facebook: $i-facebook,
    linkedin: $i-linkedin,
    more: $i-plus,
    pinterest: $i-pinterest,
    print: $i-print,
    reddit: $i-reddit,
    twitter: $i-twitter,
    whatsapp: $i-whatsapp,
    x: $i-x-social
  );

  @each $class, $icon in $icons {
    &.shr-#{$class}::before {
      content: $icon;
    }
  }

  &:visited {
    @extend %accent-foreground;
  }

  &:hover {
    color: $white;
    text-decoration: none;
    @extend %accent-background;
  }

  &::before {
    display: block;
  }
}

.dk-share-buttons {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0 5px;
  margin: 0 0 0 0.5rem;
  transition: row-gap 250ms ease-in-out;

  // The link/button
  & > * {
    @extend %_share-button;
  }

  .shr-more {
    &, &:hover {
      transition: 100ms linear;
      transition-property: color, background-color;
    }

    &::before {
      transition: transform 100ms linear;
    }
  }

    // Additional buttons not expanded
    // Repeated .shr-more needed to increase specificity
  &:not(.expanded) .shr-more.shr-more.shr-more ~ * {
    opacity: 0;
    max-height: 0;
    border-width: 0;
    visibility: hidden;
    transition: max-height 250ms ease-in-out 75ms, border-width 250ms ease-in-out, opacity 100ms linear, visibility 0s linear 325ms;
  }

  // Additional buttons expanded
  &.expanded {
    row-gap: 5px;

    .shr-more {
      color: $white;
      @extend %accent-background;

      &::before {
        transform: rotate(45deg);
      }

      & ~ * {
        opacity: 1;
        visibility: visible;
        transition: max-height 250ms ease-in-out, border-width 250ms ease-in-out, opacity 100ms linear 225ms, visibility 0s linear;
      }
    }
  }
}

.dk-share {
  display: flex;
  flex-wrap: wrap;
  min-height: rem($_size-small);
  margin: 2rem 0;
  line-height: 2rem;
  visibility: hidden;

  &.ready {
    visibility: visible;
  }

  & > :first-child {
    font: bold 1.2rem #{$open-sans};
    display: block;
    height: rem($_size-small);
    line-height: rem($_size-small);
    margin: 0;
  }

  @include tablet {
    &:not(.no-resize) {
      min-height: rem($_size-large);

      & > :first-child {
        height: rem($_size-large);
        line-height: rem($_size-large);
      }

      .dk-share-buttons > * {
        @include _share-button-size($_size-large);
      }
    }
  }

  @include mobile {
    min-height: rem($_size-large);

    & > :first-child {
      height: rem($_size-large);
      line-height: rem($_size-large);
    }

    .dk-share-buttons > * {
      @include _share-button-size($_size-large);
    }
  }
}

@use '../lib/neat';
@use '../common' as *;

.pt-meeting {
  .main-image {
    margin-top: rem(20);

    img {
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }

    @include mobile {
      display: none;
    }
  }

  .meeting-summary {
    margin: 1rem 0 1.5rem;
    line-height: 2;
    font-family: $open-sans;
    font-weight: $semibold;

    & > * {
      display: inline-block;
      margin-right: 0.5rem;

      &::before {
        @extend %icon-base;
        @include icon-hover-fix;
        color: $medium-gray;
        text-decoration: none;
        margin-right: rem(5);
      }
    }

    .date::before {
      content: $i-calendar-o;
    }

    .contacts::before {
      content: $i-address-book;
    }

    .location::before {
      content: $i-map-marker;
    }

    @include desktop {
      .contacts, .location {
        display: none;
      }
    }
  }

  .register {
    margin-bottom: 2rem;

    @include desktop {
      &.mobile {
        display: none;
      }
    }

    a.button, .button-disabled {
      display: block;
      position: relative;
      z-index: 1;
      max-width: rem(300);
      margin-bottom: rem(5);
      text-align: center;
      font-weight: $semibold;
    }

    a.button {
      &.exit-link {
        &::after {
          @extend %icon-base;
          content: $i-exit;
          display: inline-block;
          margin-left: rem(5);
        }

        & + .eld {
          display: none;
        }
      }
    }

    .button-disabled {
      margin: 0.8rem 0 rem(5);
      padding: 0.5rem 2rem;
      border: 1px solid $button-outline;
      border-radius: rem(3);
      font-size: 1rem;
      font-family: $open-sans;
      background-color: $button-gray;
      color: $button-text;
    }

    .deadline {
      font-family: $open-sans;
      font-size: rem(12.5);
    }
  }

  @include desktop {
    .meeting-body {
      @include neat.row;

      & > :first-child {
        @include neat.span-columns(4 of 6);
      }

      & > :last-child {
        @include neat.span-columns(2 of 6);
      }
    }
  }

  .left-content {
    h3 {
      @extend %size-h4;
    }

    h4 {
      @extend %size-h5;
    }

    h5 {
      @extend %size-h6;
    }

    // Retain support for legacy markup (before mitigating multiple H1's)
    h2.sr-only {
      & ~ h2 {
        @extend %size-h4;
      }

      & ~ h2 ~ h3 {
        @extend %size-h5;
      }

      & ~ h2 ~ h4 {
        @extend %size-h6;
      }
    }

    h2, h3, h4, h5 {
      margin-bottom: 0.25rem;

      &:not(.sr-only) + p {
        margin-top: 0;
      }
    }
  }

  .right-rail {
    section:not(.dk-share) {
      margin: 2rem 0;
      font-family: $open-sans;
      line-height: 1.6;

      &:first-of-type {
        margin-top: 0;
      }

      h3 {
        @extend %size-h4;
        margin-bottom: 0.5rem;
        padding-bottom: 0.5rem;
        border-bottom: solid 1px $divider-stroke;
        line-height: 1;

        i::before {
          top: rem(2);
          margin-right: rem(5);
        }
      }

      p:first-of-type {
        margin-top: 0.5rem;
      }
    }

    .location p.webinar {
      &:not(:last-child) {
        margin-bottom: 0;
      }

      & + p {
        margin-top: 0;
      }
    }

    @include mobile {
      margin-top: 2rem;
      padding-top: 1.5rem;
      border-top: solid 2px $divider-stroke;
    }
  }
}

dl.agenda {
  dt {
    font-family: $open-sans;
    margin-bottom: rem(4);
  }

  dd {
    line-height: 1.5;
    display: list-item;
  }

  dd + dd {
    margin-top: 1rem;
  }

  dd + dt {
    margin-top: 1rem;
  }
}

// NIDDK edit: converted to a mixin to avoid this getting repeated every time Neat is @use'd

@mixin set-border-box-sizing {
  @if $border-box-sizing == true {
    html { // http://bit.ly/1qk2tVR
      box-sizing: border-box;
    }

    * {
      &,
      &::after,
      &::before {
        box-sizing: inherit;
      }
    }
  }
}

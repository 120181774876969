// Content folding (hide some content beneath a "More" button)
%foldable-container {
  .fold {
    display: block;
    clear: both;
    cursor: pointer;

    @at-root {
      html:not(.editmode) & ~ :not(.unfold) {
        display: none;
      }

      html:not(.editmode) & ~ .unfold ~ * {
        display: initial;

        &.cta {
          display: block;
        }
      }
    }
  }

  .no-outline:focus {
    outline: none;
  }
}

.foldable {
  @extend %foldable-container;
}

@use 'sass:color';
@use 'sass:math';
@use '../common' as *;

.collapse-list {
  @include mobile {
    ul {
      margin-left: rem(4);
    }
  }

  li {
    position: relative;

    input[type=checkbox] {
      position: absolute;
      z-index: -500;
      left: -5000px;

      & + label {
        display: block;
        position: absolute;
        top: rem(2);
        left: -1.5rem;
        width: rem(16);
        height: rem(16);
        font-size: 0;
        text-indent: rem(5000);
        color: transparent;
        @extend %unselectable;

        &:hover::before {
          background-color: color.adjust($default-accent-color, $lightness: 15%);
          //background-color: $bg-gray;
          //color: $default-accent-color;
        }

        &::before {
          @extend %icon-base;
          content: $i-plus;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: rem(16);
          height: rem(16);
          text-align: center;
          text-indent: 0;
          border: solid 1px $default-accent-color;
          border-radius: rem(3);
          background-color: $default-accent-color;
          color: $white;
          font-size: 0.8rem;
          line-height: math.div(14, 10);
          cursor: pointer;
        }
      }

      &:focus + label::before {
        outline: solid 2px rgba($blue, 0.5);
      }

      & ~ ul {
        display: none;
      }

      &:checked {
        & + label::before {
          content: $i-minus;
        }

        & ~ ul {
          display: block;
        }
      }
    }
  }
}

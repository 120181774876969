@use '../lib/bourbon';
@use '../variables' as *;
@use '../functions' as *;

@mixin line-clamp($lines: 2, $font-size: $em-base, $line-height: 1.4) {
  $fs: bourbon.strip-unit($font-size);

  display: block;
  position: relative;
  height: rem($fs * $lines * $line-height);
  font-size: rem($fs);
  line-height: $line-height;
  overflow: hidden;

  &.overflow {
    //word-wrap: break-word;
    //word-break: break-all;

    &::after {
      content: '\2026'; // Horizontal ellipsis
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 1.5em;
      font-weight: bold;
      text-align: right;
      background-image: linear-gradient(to right, #{rgba($white, 0)} 0%, $white 30%);
    }

    @at-root .webkitlineclamp & {
      text-overflow: ellipsis;
      display: -webkit-box;
      word-break: normal;
      -webkit-line-clamp: $lines;
      -webkit-box-orient: vertical;

      &::after {
        content: normal;
      }
    }
  }
}

@mixin line-clamp-grow($lines: 2, $font-size: $em-base, $line-height: 1.4) {
  $fs: bourbon.strip-unit($font-size);

  @include line-clamp($lines, $font-size, $line-height);
  height: auto;
  max-height: rem($fs * $lines * $line-height);
}

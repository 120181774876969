$icomoon-font-family: "dki" !default;
$icomoon-font-path: "fonts" !default;

$i-arrow-left: unquote('"\\e001"');
$i-arrow-right: unquote('"\\e000"');
$i-arrow-up: unquote('"\\e003"');
$i-arrow-down: unquote('"\\e002"');
$i-chevron-left: unquote('"\\e035"');
$i-chevron-right: unquote('"\\e036"');
$i-chevron-up: unquote('"\\e037"');
$i-chevron-down: unquote('"\\e038"');
$i-triangle-left: unquote('"\\e03f"');
$i-triangle-right: unquote('"\\e040"');
$i-triangle-up: unquote('"\\e041"');
$i-triangle-down: unquote('"\\e03e"');
$i-play: unquote('"\\e06a"');
$i-pause: unquote('"\\e06c"');
$i-stop: unquote('"\\e06d"');
$i-exit: unquote('"\\e03a"');
$i-exit-nih: unquote('"\\e03b"');
$i-top-en: unquote('"\\e03c"');
$i-top-es: unquote('"\\e03d"');
$i-menu: unquote('"\\e011"');
$i-share: unquote('"\\e039"');
$i-address-book: unquote('"\\e07b"');
$i-blog: unquote('"\\e07a"');
$i-mobile: unquote('"\\e022"');
$i-video: unquote('"\\e01d"');
$i-audio: unquote('"\\e042"');
$i-shape-circle: unquote('"\\e048"');
$i-shape-hexagon: unquote('"\\e04e"');
$i-shape-pentagon-arrow: unquote('"\\e04f"');
$i-shape-square: unquote('"\\e050"');
$i-google-scholar-square: unquote('"\\e918"');
$i-orcid-square: unquote('"\\e919"');
$i-researchgate-square: unquote('"\\e900"');
$i-researchgate: unquote('"\\e901"');
$i-cells: unquote('"\\e051"');
$i-collaboration: unquote('"\\e052"');
$i-double-chevron: unquote('"\\e053"');
$i-examine-report: unquote('"\\e054"');
$i-funding: unquote('"\\e055"');
$i-grant-apply: unquote('"\\e056"');
$i-grant-award: unquote('"\\e057"');
$i-grant-examine: unquote('"\\e058"');
$i-grant-fund: unquote('"\\e059"');
$i-grant-funding: unquote('"\\e05a"');
$i-grant-manage: unquote('"\\e05b"');
$i-grant-review: unquote('"\\e05c"');
$i-handshake: unquote('"\\e05d"');
$i-node-cluster: unquote('"\\e05e"');
$i-newspaper: unquote('"\\e05f"');
$i-new-investigator: unquote('"\\e060"');
$i-mortarboard: unquote('"\\e061"');
$i-medical-microscope: unquote('"\\e062"');
$i-lightbulb: unquote('"\\e063"');
$i-id-badge: unquote('"\\e064"');
$i-person-presenting: unquote('"\\e066"');
$i-small-business: unquote('"\\e067"');
$i-tools: unquote('"\\e068"');
$i-dna: unquote('"\\e045"');
$i-test-tubes: unquote('"\\e047"');
$i-robotic-arm: unquote('"\\e04d"');
$i-clipboard: unquote('"\\e046"');
$i-molecule: unquote('"\\e04a"');
$i-mouse: unquote('"\\e04c"');
$i-mortar-pestle: unquote('"\\e049"');
$i-people-discussing: unquote('"\\e065"');
$i-additional-reading: unquote('"\\e06e"');
$i-toolbox: unquote('"\\e06f"');
$i-weight-management: unquote('"\\e070"');
$i-school-responsibilities: unquote('"\\e071"');
$i-risk-factors: unquote('"\\e072"');
$i-checklists: unquote('"\\e073"');
$i-blood-screening: unquote('"\\e074"');
$i-diabetes: unquote('"\\e075"');
$i-download-file: unquote('"\\e076"');
$i-kidney-disease: unquote('"\\e077"');
$i-clinical-trials: unquote('"\\e902"');
$i-publications: unquote('"\\e903"');
$i-research-materials: unquote('"\\e904"');
$i-repository: unquote('"\\e913"');
$i-lab-software: unquote('"\\e91a"');
$i-lab-servers: unquote('"\\e91b"');
$i-pulse-programs: unquote('"\\e91c"');
$i-standards: unquote('"\\e91e"');
$i-webinar-s: unquote('"\\e921"');
$i-pos-block-left: unquote('"\\e905"');
$i-pos-block-center: unquote('"\\e906"');
$i-pos-float-left: unquote('"\\e907"');
$i-pos-float-right: unquote('"\\e908"');
$i-size-small: unquote('"\\e909"');
$i-size-medium: unquote('"\\e90a"');
$i-size-large: unquote('"\\e90b"');
$i-size-fill: unquote('"\\e90c"');
$i-webinar: unquote('"\\e911"');
$i-chat-bubble: unquote('"\\e912"');
$i-live-chat-o: unquote('"\\e914"');
$i-live-chat: unquote('"\\e915"');
$i-facebook-c: unquote('"\\e90d"');
$i-twitter-c: unquote('"\\e90e"');
$i-youtube-c: unquote('"\\e90f"');
$i-instagram: unquote('"\\e910"');
$i-linkedin-c: unquote('"\\e91f"');
$i-x-social-c: unquote('"\\e920"');
$i-quote-open: unquote('"\\e924"');
$i-quote-close: unquote('"\\e925"');
$i-copy: unquote('"\\e916"');
$i-angle-left: unquote('"\\e029"');
$i-angle-right: unquote('"\\e006"');
$i-angle-up: unquote('"\\e007"');
$i-angle-down: unquote('"\\e005"');
$i-angle-double-left: unquote('"\\e009"');
$i-angle-double-right: unquote('"\\e00a"');
$i-angle-double-up: unquote('"\\e00b"');
$i-angle-double-down: unquote('"\\e008"');
$i-thick-left: unquote('"\\e02f"');
$i-thick-right: unquote('"\\e030"');
$i-thick-up: unquote('"\\e031"');
$i-thick-down: unquote('"\\e02d"');
$i-plus: unquote('"\\e01a"');
$i-minus: unquote('"\\e019"');
$i-times: unquote('"\\e012"');
$i-download: unquote('"\\e034"');
$i-print: unquote('"\\e025"');
$i-spinner: unquote('"\\e06b"');
$i-check: unquote('"\\e043"');
$i-exclamation-circle: unquote('"\\e044"');
$i-question-circle: unquote('"\\e917"');
$i-search: unquote('"\\e00f"');
$i-search-plus: unquote('"\\e00e"');
$i-search-minus: unquote('"\\e010"');
$i-clock-o: unquote('"\\e020"');
$i-map-marker: unquote('"\\e023"');
$i-calendar: unquote('"\\e079"');
$i-calendar-o: unquote('"\\e078"');
$i-tty: unquote('"\\e021"');
$i-envelope: unquote('"\\e004"');
$i-envelope-o: unquote('"\\e02a"');
$i-facebook: unquote('"\\e01c"');
$i-x-social: unquote('"\\e922"');
$i-twitter: unquote('"\\e02b"');
$i-linkedin: unquote('"\\f0e1"');
$i-rss: unquote('"\\e024"');
$i-envelope-square: unquote('"\\e027"');
$i-facebook-square: unquote('"\\e01b"');
$i-twitter-square: unquote('"\\e01e"');
$i-x-social-square: unquote('"\\e923"');
$i-linkedin-square: unquote('"\\e07c"');
$i-rss-square: unquote('"\\e026"');
$i-phone-square: unquote('"\\e91d"');
$i-user-medical: unquote('"\\e033"');
$i-gear: unquote('"\\e032"');
$i-whatsapp: unquote('"\\f232"');
$i-reddit: unquote('"\\f281"');
$i-pinterest: unquote('"\\f231"');


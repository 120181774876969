/// Forces the element to fill its parent container.
///
/// @example scss - Usage
///   .element {
///     @include fill-parent;
///   }
///
/// @example css - CSS Output
///   .element {
///     width: 100%;
///     box-sizing: border-box;
///   }

@mixin fill-parent() {
  width: 100%;

  @if $border-box-sizing == false {
    box-sizing: border-box;
  }
}

@use '../common' as *;



.expandable:not(.ready) {
  position: relative;

  @include mobile {
    & > * {
      display: none;
    }

    &[data-collapse="full"],
    &[data-collapse="none"] {
      & > * {
        display: block;
      }
    }
  }

  @include desktop {
    & > * {
      display: block;
    }

    &[data-collapse="full"],
    &[data-collapse="both"] {
      & > * {
        display: none;
      }
    }
  }

  &::before {
    content: 'Show description';
    display: block;
    visibility: hidden;
    font: 1rem / 1.8 $open-sans;
    margin: 1rem 0;
  }
}


// data-collapse specifies initial collapsed state for the following values: none, mobile (default), full, both
// data-expand-text, data-collapse-text


.dk-expandable {
  position: relative;
  margin: 1rem 0;
  z-index: 1;

  .expandable-toggle {
    border: 0;
    padding: 0;
    background: transparent;
    color: $link-standard;
    font: 1rem / 1.8 $open-sans;
    cursor: pointer;
    appearance: none;

    &:hover {
      color: $link-hover;
      text-decoration: underline;
    }

    &::after {
      @extend %icon-base;
      content: $i-angle-down;
      margin-left: rem(4);
      @include icon-hover-fix;
    }

    &[aria-expanded=true]::after {
      content: $i-angle-up;
    }
  }

  .expandable {
    @extend %remove-child-bookend-margins;
    margin: 1rem 0;

    &:focus {
      outline: none;
    }
  }

  @include print {
    .expandable-toggle { display: none; }
  }
}

@use '../lib/bourbon';
@use '../lib/neat';
@use '../common' as *;

.tab-sections {
  position: relative;

  .tab-menu {
    position: relative;
    z-index: 1;
    width: rem(200);
    height: 2.5rem;
    max-width: 100%;
    border: 1px solid $button-outline;
    border-radius: rem(3);
    padding-left: rem(30);
    font: {
      family: $open-sans;
      weight: $semibold;
      size: rem(14);
    }
    line-height: 2.3rem;
    background-color: $white;
    margin-bottom: rem(17);
    cursor: pointer;

    &::before {
      @extend %icon-base;
      content: $i-chevron-down;
      position: absolute;
      left: rem(8);
      top: 50%;
      transform: translateY(-50%);
      color: $blue;
    }

    @include desktop {
      display: none;
    }
  }

  .tab-bar {
    & > * {
      @extend %unselectable;
    }

    @include mobile {
      position: absolute;
      top: 0;
      left: 0;
      min-width: rem(214);
      z-index: -20;
      transform: translate(#{rem(-7)}, 2.9rem);
      margin: 0;
      padding: 0;
      border: 1px solid $button-outline {
        radius: rem(5);
      }
      background-color: $white;
      list-style: none;
      font: {
        size: rem(13);
        family: $open-sans;
      }
      box-shadow: 0 rem(3.5) rem(12) rgba($black, 0.13);
      opacity: 0;
      transition: opacity 0.2s linear;

      &.shown {
        z-index: 20;
        opacity: 1;
        outline: none;
        transition: opacity 0.2s linear;
      }

      &::before {
        display: block;
        position: absolute;
        top: rem(-19);
        left: rem(7);
        width: rem(30);
        height: rem(30);
        color: $white;
        content: $i-triangle-up;
        font: {
          size: rem(30);
          family: $dk-icons;
        }
        line-height: 1;
        text: {
          rendering: auto;
          shadow: 0 -1px 0 $button-outline;
        }
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        @at-root {
          .no-textshadow & {
            color: $divider-stroke;
          }
        }
      }

      & > * {
        display: block;
        padding: 0.75rem;
        border-bottom: solid 1px $divider-stroke;
        cursor: pointer;

        &:last-child {
          border-bottom: 0;
        }

        &:hover {
          background-color: $bg-ash;
        }

        &[aria-selected="true"] {
          position: relative;
          background-color: $ln-current;
          font-weight: $semibold;
          color: $text-dark;
          cursor: default;
        }
      }
    }

    @include desktop {
      @include neat.row;
      border-bottom: solid 1px $light-gray;
      font-family: $open-sans;

      &.shown {
        outline: none;

        & > * {
          border-color: $light-gray;
        }
      }

      & > * {
        display: block;
        float: left;
        margin-right: rem(5);
        padding: 1rem;
        color: $link-standard;
        font-weight: $semibold;
        border-radius: rem(5) rem(5) 0 0;
        cursor: pointer;
        border: dashed 0 $white;
        border-width: 1px 1px 0;

        &:hover {
          background-color: $bg-gray;
        }

        &:focus {
          outline: none;
          border-color: $light-gray;
        }

        &[aria-selected="true"] {
          position: relative;
          background-color: $ln-current;
          font-weight: $semibold;
          color: $text-dark;
          cursor: default;
          border-style: solid;

          &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: calc(50% - #{rem(6)});
            @include bourbon.triangle("up", rem(12), rem(6), $text-general);
          }
        }
      }
    }
  }

  section {
    @include anchor-margin(10rem); // Differs from standard scroll margin to allow room for the tab nav button

    &:focus {
      outline: 0;
    }

    &[aria-hidden="true"] {
      display: none;
    }

    @include mobile {
      position: relative;
      margin-top: 2rem;

      // Must be ::after to allow for legacy .no-scrollmargin support
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: -1rem;
        width: 100%;
        border-bottom: solid 1px $light-gray;
      }
    }

    // Legacy browser support
    @at-root .no-scrollmargin & {
      // Legacy anchor margin fix; differs to allow room for the tab nav button
      &::before {
        height: 10rem;
        margin-top: -10rem;
      }

      @include mobile {
        position: static;
        margin-top: 0;

        &::after {
          top: auto;
          bottom: calc(100% - 3.8rem);
        }
      }
    }
  }
}

@use '../lib/neat';
@use '../variables' as *;
@use '../functions' as *;
@use 'placeholders';

@media print {

  .site-wrap {
    display: flex;
    flex-direction: column;
  }

  %-display-none {
    display: none;
  }

  %-display-none-important {
    display: none !important;
  }

  #main-header {
    height: 125px !important;

    .pre-header {
      @extend %-display-none;
    }

    .sticky-header {
      position: static !important;

      #site-search, .mobile-toolbar, .primary-nav {
        @extend %-display-none;
      }
    }

    &.offscreen {
      .site-logo {
        top: rem(28) !important;
        width: rem(300) !important;

        &.dk-logo-full {
          visibility: visible !important;
        }
        &.dk-logo-abbrev {
          visibility: hidden !important;
        }
      }
      .sticky-header {
        box-shadow: none !important;
      }
    }
  }

  .dk-breadcrumb-row {
    @extend %-display-none-important;
  }

  .dk-main-container {
    box-shadow: none;
    padding: 0;

    .dk-leftnav {
      @extend %-display-none;
    }

    .dk-content {
      @include neat.span-columns(12);
      padding: 0;

      h1 {
        margin-top: 0;
      }
    }
  }
  #main-footer {
    #contact {
      address {
        @include neat.span-columns(12);
      }

      .follow-us {
        @extend %-display-none;
      }
    }

    .footer-sitemap, .sub-footer {
      @extend %-display-none;
    }
  }

  #scrollUp {
    @extend %-display-none;
  }

  .dk-accdn {
    .acc-main, .acc-title {
      border: 0 !important;
    }

    .acc-ctrl {
      @extend %-display-none;
    }

    .acc-title button {
      padding: 0 !important;

      &::after {
        content: normal !important;
      }
    }

    .acc-content {
      display: block !important;
      padding: 1rem 0 1rem !important;
    }
  }

  figure {
    .expand {
      @extend %-display-none-important;
    }

    .credit {
      padding-top: rem(5) !important;
      color: $text-general !important;
      border-bottom: solid 1px $divider-stroke;

      &::before {
        @extend %-display-none-important;
      }
    }
  }

  .dk-share {
    @extend %-display-none-important;
  }

  .no-print {
    @extend %-display-none-important;
  }

  .steps-list.steps-list.steps-list > *::before, .callout {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .mobile-swap.mobile-swap .swap-mobile {
    @extend %-display-none;
  }

  // Subscribe panel
  .dk-subscribe-panel {
    @extend %-display-none-important;
  }

  // Faceted search pages

  .pt-facet-search.pt-facet-search, .dk-facet-search {
    .fs-search, .fs-facets {
      @extend %-display-none;
    }
  }

  // Blog pages

  .pt-blog.pt-blog {
    .blog-title.blog-title {
      &, .blog-icon {
        color: $text-general;
      }

      .blog-icon {
        border-color: $text-general;
      }
    }

    article {
      max-width: 100%;

      .subscribe-share {
        @extend %-display-none;
      }

      .related-posts {
        @include neat.row;

        article {
          @include neat.span-columns(6 of 12);
        }
      }
    }

    .main-image figcaption {
      text-align: left;
    }

    .dk-pagination {
      @extend %-display-none;
    }

    .dk-blog-rail {
      section {
        &.subscribe, &.dk-share, &.search, &.featured-posts, &.tags, &.archive {
          @extend %-display-none-important;
        }
      }
    }
  }
}

@use 'sass:color';
@use 'sass:list';
@use '../common' as *;

// The classes and selectors for RoundBox are intended to be implemented
// as modifiers to allow for a variety of combinations and options.
// `.roundbox` should be applied as a base class, with additional modifier
// classes like `.rb-[modifier]` (e.g., `.rb-dark`).

%_relative {
  position: relative;
}

// Filled RoundBox (no shadow, standard color text and links)
%roundbox-fill {
  & > .content {
    background: var(--rb-background, #{$bg-sea-glass}); // Sea Glass background unless overridden
    box-shadow: none;
  }

  img {
    box-shadow: none;
  }
}

// Dark filled RoundBox (no shadow, white text and links)
%roundbox-fill-dark {
  & > .content {
    background: var(--rb-background, #{$bg-lavender}); // Lavender background unless overridden
    color: $white;
    box-shadow: none;
  }

  img {
    box-shadow: none;
  }

  a {
    text-decoration: underline;

    &, &:hover, &:visited, &:active {
      color: $white;
    }

    &:hover, &:active {
      text-decoration: none;
    }
  }

  .buttons.buttons > a {
    color: $text-dark;
    background-color: color.change($white, $alpha: 0.7);

    &:hover {
      background-color: color.change($white, $alpha: 0.85);
    }
  }
}

%roundbox-serif {
  font-family: $merriweather;
}

%roundbox-sans {
  font-family: $open-sans;
}

%roundbox-img {
  @extend %_relative;

  border-radius: rem(5);
  z-index: 1;
  box-shadow: $tile-shadow;
}

%roundbox-quote-icon {
  position: absolute;
  font-family: $dk-icons;
  font-size: 3rem;
  color: #E1E3F2;
}

// Floated image with longer content that wraps
%roundbox-prose {
  img {
    display: block;
    margin-left: 1.5rem;
    max-width: 8.5rem;
  }

  img + .content {
    top: -1rem;
    padding-top: 2rem;
    margin-bottom: -1rem; // Negate top value to a account for offset
  }

  @include desktop(true) {
    img + .content {
      top: 1.5rem;
      min-height: 11.5rem; // Encompass, at a minimum, a square image
      padding-top: 1.5rem;
      margin-bottom: 1.5rem; // Negate top value to account for offset
    }

    img {
      float: left;
      margin-right: 1.5rem;
      margin-bottom: 0.25rem;
      max-width: 11.5rem;
      shape-outside: inset(0 -1.5rem 1.5rem 0); // Adjust text wrapping for the offset image and for Safari
    }
  }
}

// Floated image with longer content that wraps
%roundbox-buttons {
  font-family: $open-sans;
  font-weight: $semibold;

  img {
    display: block;
    float: right;
    max-width: 7rem;
    max-height: 7rem;
    margin-left: 0.5rem;
    margin-right: 1.5rem;
    margin-bottom: 0.25rem;
    shape-outside: inset(0 0 2.5rem -0.5rem); // Adjust text wrapping for the offset image and for Safari
    box-shadow: none;
  }

  .content {
    border-radius: rem(10);
  }

  img + .content {
    top: 2.5rem;
    min-height: 8rem; // Encompass, at a minimum, a square image
    margin-bottom: 2.5rem; // Negate top value to account for offset
  }

  .text {
    @extend %remove-child-bookend-margins;
  }

  .text + .buttons {
    margin-top: 1.5rem;

    &:not(:has(a)) {
      margin-top: 0;
    }
  }

  .buttons {
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
    gap: 1.5rem 2rem;

    & > a {
      display: flex;
      flex: 1 0 auto;
      align-items: center;
      justify-content: center;
      min-width: 200px;
      max-width: 300px;
      width: calc(50% - 1rem);
      border: 1px solid $button-outline;
      border-radius: rem(3);
      padding: 1rem;
      background-color: color.change($white, $alpha: 0.8);
      text-align: center;
      transition: background-color ease-in-out 100ms;

      &, &:hover, &:active, &:visited {
        text-decoration: none;
        color: $text-general;
      }

      &:hover {
        background-color: color.change(#e6e6e6, $alpha: 0.8);
      }
    }
  }

  @include desktop(true) {
    img {
      margin-left: 2rem;
      margin-right: 2.5rem;
      max-width: 8rem;
      max-height: 8rem;
      shape-outside: inset(0 0 2.5rem -2rem); // Adjust text wrapping for the offset image and for Safari
    }

    .content {
      padding: 1.5rem 2rem;
    }
  }
}

%roundbox-quote {
  margin: 4rem 0 0;
  font-size: rem(16);

  .content {
    .quote-statement {
      * {
        font-size: rem(16);
        line-height: 1.25;
      }
    }

    .quote-attribution {
      .quote-source {
        margin-bottom: 0.5rem;

        * {
          font-size: rem(16);
        }
      }

      .quote-bio {
        margin: 0;

        * {
          font-size: rem(12);
          line-height: 1.5;
        }
      }
    }

    .quote-statement, .quote-source, .quote-bio {
      @extend %remove-child-bookend-margins;
    }
  }

  @include desktop(true) {
    .content {
      display: flex;
      flex-wrap: wrap;

      img {
        margin-top: -3rem;
        margin-left: 0;
        margin-bottom: auto;
      }
    }
  }

  @include mobile {
    .content {
      img {
        margin: -9rem 0 0.5rem auto;
      }
    }
  }
}

// TODO
%roundbox-quote-footer {
  font-size: rem(12);
}

%roundbox-quote-light {
  margin-bottom: 2rem;

  &:not(:has(img)) {
    margin-top: 3.5rem;
  }

  img {
    margin-left: 0;
    border-radius: rem(10);
    box-shadow: $tile-shadow;
  }

  .content {
    border-radius: rem(10);

    &.content {
      padding-top: 2rem;
    }

    .quote-statement::before {
      @extend %roundbox-quote-icon;
      content: $i-quote-open;
      top: -1.5rem;
      margin-left: 1.5rem;
    }

    .quote-source {
      margin-top: 1rem;
    }
  }

  &.rb-right {
    .quote-attribution {
      text-align: right
    }

    @include desktop(true) {
      &:has(img) {
        padding-right: 1.5rem;

        .content {
          flex-direction: row-reverse;

          img {
            right: -3rem;
            margin-left: -1.5rem;
            margin-right: 0;
          }
        }
      }
    }
  }

  @include desktop(true) {
    &:not(.rb-right) {
      padding-left: 1.5rem;

      img {
        left: -3rem;
        margin-right: -1.5rem;
      }
    }

    .quote-statement {
      flex: 1;
      margin-bottom: 0.5rem;
    }
  }

  @include mobile {
    img {
      right: -3rem;
      margin-top: -9.5rem;
      margin-left: auto;
    }

    .content:has(img) {
      margin-top: 9.5rem;
      margin-left: 0;
      margin-right: 1.5rem;

      .quote-statement {
        margin-top: 1rem;
      }

      .quote-attribution {
        text-align: right
      }
    }
  }
}

%roundbox-quote-dark {
  margin-bottom: 4rem;

  .content {
    font-family: $open-sans;

    .quote-attribution {
      position: relative;
      flex: 1;

      .quote-statement {
        margin-top: 0;
        margin-bottom: 1rem;
      }

      &::before, &::after {
        @extend %roundbox-quote-icon;
      }

      &::before {
        content: $i-quote-open;
        top: -3.5rem;
        margin-left: 1rem;
      }

      &::after {
        content: $i-quote-close;
        bottom: -3.5rem;
        right: 0;
        margin-right: 1rem;
      }
    }
  }

  @include desktop(true) {
    &.rb-right {
      img {
        order: 2;
        margin-left: 1.5rem;
        margin-right: 0;
      }

      .quote-attribution {
        footer {
          margin-right: 1rem;
        }
      }
    }
  }

  @include mobile {
    .content:has(img) {
      margin-top: 10.5rem;
    }
  }
}

// Standard RoundBox styling; start with white background and shadow
%roundbox {
  @extend %_relative;
  @extend %clearfix;

  margin-left: 0;
  margin-right: 0;

  &:not(.rb-quote) {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  img {
    @extend %roundbox-img;
  }

  // The content area is shifted around as needed and given the colors, shadow, etc.
  .content {
    @extend %_relative;
    @extend %remove-child-bookend-margins;

    border-radius: rem(5) rem(5) rem(30);
    padding: 1.5rem;
    background: var(--rb-background, #{$white});
    color: $text-general;
    box-shadow: $tile-shadow;
  }

  // RoundBox modifier classes
  &.rb-fill {
    @extend %roundbox-fill;
  }

  &.rb-dark {
    @extend %roundbox-fill-dark;
  }

  &.rb-sans {
    @extend %roundbox-sans;
  }

  &.rb-serif {
    @extend %roundbox-serif;
  }

  &[class*="bg-"] {
    @extend %roundbox-fill;
    background-color: transparent;
  }

  // RoundBox type classes
  &.rb-prose {
    @extend %roundbox-prose;
  }

  &.rb-buttons {
    @extend %roundbox-buttons;
  }

  &.rb-quote {
    @extend %roundbox-quote;
    @extend %roundbox-prose;

    &:is(.rb-fill,.rb-dark,[class*="bg-"]) {
      @extend %roundbox-quote-dark;
    }

    &:not(:is(.rb-fill,.rb-dark,[class*="bg-"])) {
      @extend %roundbox-quote-light;
    }
  }

  // Background color options (expected refactor later)
  $invert: 'bg-charcoal', 'bg-onyx', 'bg-dark-cyan', 'bg-dark-blue', 'bg-lavender'; // Colors needing white text
  @each $class, $color in $background-colors {
    &.#{$class} {
      --rb-background: #{$color};

      @if list.index($invert, $class) {
        @extend %roundbox-fill-dark;
      }
    }
  }

  @include print {
    .content {
      border: 1px solid $button-outline;
      box-shadow: none !important;
      color: $text-general !important;
      background-color: $white !important;
    }
  }
}

// Baseline RoundBox styling
.roundbox {
  @extend %roundbox;
}

$_transparent-pixel: url(data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==);

.roundbox-hero {
  position: relative;
  margin: 2rem 0;
  border-radius: rem(5) rem(5) rem(30);
  background: var(--hero-bg, #{$bg-dark-blue});
  color: $white;
  overflow: hidden;

  &:first-child {
    margin-top: 0.5rem;
  }

  :is(.title,h1,h2) {
    @extend %font-heading;
    @extend %size-h2;
  }

  .content {
    @extend %dk-paragraph-text;
  }

  &.rh-title {
    .hero-grid {
      gap: 1.5rem 0;
    }

    :is(.title,h1,h2) {
      @extend %size-h1;
    }

    .content {
      @extend %font-heading;
      @extend %size-h3;
    }
  }

  .hero-grid {
    display: grid;
    position: relative;
    z-index: 1;
    grid-template: 'image title' auto 'image content' auto / 1fr 4fr;
    gap: 2.5rem 0;
    overflow: hidden;

    // Accent artwork for left side of hero
    &::before {
      grid-area: image;
      content: '';
      display: block;
      width: var(--hero-img-width, 100%);
      height: 100%;
      background: var(--hero-img, #{$_transparent-pixel}) no-repeat right center;
      background-size: cover;
      transform: scale(1.5) translateX(-100%);
      animation: roundbox-hero-img-shrink 1s ease-in-out 500ms forwards;
    }

    & > *, p {
      margin: 0;
    }

    p + p {
      margin-top: 0.5rem;
    }

    & > :first-child {
      grid-area: title;
      align-self: end;
      margin-top: 2rem;
      margin-right: 1.5rem;
    }

    & > :last-child {
      grid-area: content;
      padding-bottom: 1.5rem;
      margin-right: 1.5rem;
    }

    & > :not(:first-child,:last-child) {
      display: none;
    }

    .slide-up {
      position: relative;
      transform: translateY(100%);
      animation: roundbox-hero-slide-up 1s ease-in-out 500ms forwards;
    }
  }

  @include desktop(true) {
    .hero-grid {
      grid-template: 'image title' minmax(8rem, auto) 'image content' auto / 1fr 3fr;
    }

    &.rh-title .hero-grid > :last-child {
      padding-bottom: 3.5rem;
    }
  }

  @media (prefers-reduced-motion: reduce) {
    .hero-grid {
      &::before, .slide-up {
        animation: none;
        transform: none;
      }
    }
  }
}

@keyframes roundbox-hero-img-shrink {
  to {
    transform: scale(1) translateX(0);
  }
}

@keyframes roundbox-hero-slide-up {
  to {
    transform: translateY(0);
  }
}
